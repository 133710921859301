<template>
  <div class="code">
  </div>
</template>

<script>
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from 'firebase/functions'
const functions = getFunctions()
const authenticate = httpsCallable(functions, 'auth-authenticate')

export default {
  name: 'Code',
  components: {
  },
  computed: {
  },
  async created() {
    let res = await authenticate({code: this.$route.query.code})
    // let url = `${process.env.VUE_APP_API_HOST}/auth?code=${params.code}`
    let token = res.data
    console.log(token)
    await signInWithCustomToken(getAuth(), token)
    window.close()
  }
}
</script>