<template>
  <div class="login">
    <h1>Welcome,</h1>
    <form action="#" @submit.prevent="login">
      <template v-if="lock && email == ''">
        <p>Vai receber no seu email um link para definir a sua senha de acesso.</p>
        <p>O link enviado expira dentro de 1 hora</p>
      </template>
      <template v-else>
        <label for="email">Email</label>
        <InputField type="email" v-model="email" id="email" name="email" :disabled="lock"/>
        <label for="password" v-text="lock ? 'Nova Senha' : 'Senha'"></label>
        <InputField type="password" v-model="password" id="password" name="password" autocomplete="on"/>
        <label for="confirm_password" v-if="lock">Confirmar Senha</label>
        <InputField type="password" v-model="confirm_password" id="confirm_password" name="confirm_password" autocomplete="on" v-if="lock"/>
        <InputButton :class="{error}">{{ lock ? 'Gravar' : 'Login' }}</InputButton>
        <InputButton class="fenix" @click.prevent="popup" v-if="!lock">
        </InputButton>
      </template>
    </form>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, verifyPasswordResetCode, confirmPasswordReset, signInWithCustomToken } from "firebase/auth";
import InputField from "@/components/Input"
import InputButton from "@/components/Button"
const auth = getAuth()

import { getFunctions, httpsCallable } from 'firebase/functions'
const functions = getFunctions()
const authenticate = httpsCallable(functions, 'auth-authenticate')
const getInviteCode = httpsCallable(functions, 'auth-get_invite_code')

export default {
  name: 'Login',
  components: {
    InputField,
    InputButton,
  },
  data() {
    return {
      email: "",
      password: "",
      confirm_password: "",
      error: false,
      lock: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    async login() {
      if( this.lock ) {
        if( this.password.length > 4 && this.password == this.confirm_password ) {
          let inviteUrl = new URL(this.$route.query.invite)
          let inviteCode = inviteUrl.searchParams.get('oobCode')
          await confirmPasswordReset(auth, inviteCode, this.password)
        } else {
          this.error = true
          setTimeout(() => this.error = false, 400)
          return
        }
      }
      signInWithEmailAndPassword(auth, this.email, this.password)
      .catch((error) => {
        console.log(error)
        this.error = true
      });
    },
    popup() {
      let url = `${process.env.VUE_APP_AUTH_URL}?`
      url += "response_type=code"
      url += `&client_id=${process.env.VUE_APP_AUTH_CLIENT_ID}`
      // url += "&client_id=BbC5r-VmezHs9tioIopZlJLB"
      url += `&redirect_uri=${encodeURIComponent(window.location.origin + "/auth")}`
      // url += "&redirect_uri=https://www.oauth.com/playground/authorization-code.html"
      url += "&scope=photo"
      url += "&state=CnKD8ekjSuv3pLhS"
      console.log(url)

      let popup = window.open(url, 'Login', 'width=800,height=600')
      popup.addEventListener('message', (e) => {
        console.log(e)
      })
    },
    async getInviteEmail(invite) {
      try {
        if( invite ) {
          let inviteUrl = new URL(this.$route.query.invite)
          let inviteCode = inviteUrl.searchParams.get('oobCode')
          this.email = await verifyPasswordResetCode(auth, inviteCode)
          this.lock = true
        }
      } catch(err) {
        console.log(err)
      }
    },
  },
  async mounted() {
    try {
      if( this.$route.query.invite ) {
        this.getInviteEmail(this.$route.query.invite)
      } else if( this.$route.query.email ) {
        let res = await getInviteCode({email: this.$route.query.email})
        console.log(res)
        this.lock = true
      } else if( this.$route.query.code ) {
        let res = await authenticate({code: this.$route.query.code})
        let token = res.data
        await signInWithCustomToken(getAuth(), token)
        window.close()
      }
    } catch(err) {
      console.log(err)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if( vm.user != null && vm.$store.state.checkedUser ) {
        vm.$router.replace({name: 'Home'})
      }
    })
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 900;
  color: var(--color1);
}
#view.login {
  max-width: 350px;
  padding: .5rem 2rem 2rem;
}
.login {
  margin: auto;
  background-color: var(--color4);
  border-radius: 2.5rem;
  filter: drop-shadow(0 8px 18px var(--color2));
  form {
    display: flex;
    flex-direction: column;
  }
}
label {
  color: var(--color1);
}
input, label {
  margin-bottom: .75em;
}
button {
  margin-top: 1em;
}
.fenix {
  height: 40px;
  border: 2px solid var(--color1);
  background-color: white;
  background-image: url(../assets/logotipo-tecnico-c.svg);
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
