<template>
<form class="job" @submit.prevent="submit">
  <div class="input">
    <label for="title">Título de Emprego</label>
    <FormInput name="title" type="text" id="title" v-model="title" />
  </div>

  <div class="input">
    <label for="workplacePolicy">Política de local de trabalho</label>
    <FormSelect
      name="workplacePolicy"
      id="workplacePolicy"
      :options="workplacePolicies"
      v-model="workplacePolicy"
    />
  </div>

  <div class="input">
    <label for="location">Localização</label>
    <FormInput name="location" type="text" id="location" v-model="location" />
  </div>

  <div class="input">
    <label for="workType">Tipo de Emprego</label>
    <FormSelect
      name="workType"
      id="workType"
      :options="employmentType"
      v-model="workType"
    />
  </div>

  <div class="input">
    <label for="workDescription">Descrição</label>
    <FormTextArea
      name="workDescription"
      id="workDescription"
      v-model="description"
    />
  </div>

  <div class="input">
    <label for="workTags">Tags</label>
    <FormTagsSelect
      name="workTags"
      id="workTags"
      v-model="tags"
    />
  </div>

  <div class="input">
    <label for="link">Link</label>
    <FormInput name="link" type="url" id="link" v-model="link" />
  </div>

  <FormButton>Criar</FormButton>
</form>
</template>

<script>
// @ is an alias to /src
import { getDatabase, ref, push, child, update } from "firebase/database"
import { getFirestore, doc, arrayUnion, writeBatch } from "firebase/firestore"; 
// import { getDatabase, ref, set, push, child, update } from "firebase/database"
import FormInput from "@/components/Input"
import FormSelect from "@/components/Select"
import FormTextArea from "@/components/TextArea"
import FormTagsSelect from "@/components/TagsSelect"
import FormButton from "@/components/Button"

export default {
  name: 'JobOfferForm',
  components: {
    FormButton,
    FormInput,
    FormSelect,
    FormTagsSelect,
    FormTextArea,
  },
  data() {
    return {
      id: '',
      title: '',
      workplacePolicy: '',
      workType: '',
      location: '',
      description: '',
      created_at: '',
      expires_at: '',
      constraints: [],
      tags: [],
      link: '',
    }
  },
  methods: {
    reset() {
      this.title = ''
      this.workplacePolicy = ''
      this.workType = ''
      this.location = ''
      this.description = ''
      this.tags = []
    },
    submit() {
      const db = getDatabase();
      const firestore = getFirestore();
      const batch = writeBatch(firestore)
      if( this.company != null ) {
        let offerId = push(child(ref(db), 'jobs')).key;
        const updates = {}
        updates[ `jobs/${offerId}` ] = this.offerData
        for( let i = 0; i < this.tags.length; i++) {
          let tag = this.tags[i]
          updates[ `tags/${tag}/${offerId}`] = true
          batch.set(doc(firestore, "tags", tag), {
            jobs: arrayUnion(offerId)
          }, {merge: true});
        }
        batch.commit()
        update(ref(db), updates)
        .then( () => {
          this.reset()
        })
        // Add a new document in collection "cities"
        // set(ref(db, 'jobs/' + offerId), this.offerData).then( () => {
        //   this.reset()
        // });
      }
    },
  },
  computed: {
    offerData() {
      return {
        title: this.title,
        workplacePolicy: this.workplacePolicy,
        workType: this.workType,
        location: this.location,
        companyId: this.company.id,
        description: this.description.replaceAll('\n', '<br>'),
        tags: this.tags,
        link: this.link,
      }
    },
    user() {
      return this.$store.state.user
    },
    email() {
      if( this.user ) {
        return this.user.info.email
      }
      return null
    },
    company() {
      if( this.email ) {
        return this.$store.state.companies.all.find(({emails}) => emails.includes(this.email))
      }
      return null
    },
    employmentType() {
      return [
        {name: 'Full-time', 'pt-PT': 'Full-time', value: 'Full-time'},
        {name: 'Part-time', 'pt-PT': 'Part-time', value: 'Part-time'},
        {name: 'Contract', 'pt-PT': 'Contrato', value: 'Contrato'},
        {name: 'Temporary', 'pt-PT': 'Temporário', value: 'Temporário'},
        {name: 'Volunteer', 'pt-PT': 'Voluntariado', value: 'Voluntariado'},
        {name: 'Internship', 'pt-PT': 'Estágio', value: 'Estágio'},
      ]
    },
    workplacePolicies() {
      return [
        {name: 'On-Site', 'pt-PT': 'No local', value: 'No local'},
        {name: 'Hybrid', 'pt-PT': 'Hibrido', value: 'Hibrido'},
        {name: 'Remote', 'pt-PT': 'Remoto', value: 'Remoto' },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.job {
  max-width: 600px;
  margin: auto;
  padding: 50px;
  background-color: var(--color4);
  border-radius: 2rem;

  input, select, button, textarea {
    width: 100%;
    resize: vertical;
  }

  .input {
    margin-bottom: 1rem;
  }

  textarea {
    min-height: 100px;
  }
}
</style>
