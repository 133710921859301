import { initializeApp } from "firebase/app"
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};
const firebaseApp = initializeApp(firebaseConfig);
import { getAuth, connectAuthEmulator } from "firebase/auth"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
const auth = getAuth()
const database = getDatabase()
const firestore = getFirestore()
const functions = getFunctions()
const storage = getStorage()
if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099")
  connectStorageEmulator(storage, "localhost", 9199)
  connectDatabaseEmulator(database, "localhost", 9000)
  connectFirestoreEmulator(firestore, "localhost", 9080)
  connectFunctionsEmulator(functions, "localhost", 5001)
}

export default {firebaseApp, auth, database, firebaseConfig}