<template>
  <div class="new">
    <CompanyForm/>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from '@/loadAxios'
import CompanyForm from '@/components/CompanyForm'

export default {
  name: 'Profile',
  props: {
  },
  components: {
    CompanyForm,
  },
  data() {
    return {
      name: '',
    }
  },
  methods: {
    submit() {
      console.debug('submitting')
    },
  },
  computed: {
  },
  watch: {
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.new {
  form {
    background-color: var(--color4);
    padding: var(--gap);
    border-radius: var(--gap);
    width: 90%;
    max-width: 500px;
    margin: auto;

    input, button {
      width: 100%;
    }
  }
}
</style>