import store from "@/store/index"
import { getDatabase, ref, onValue } from 'firebase/database'
const database = getDatabase()

const jobsRef = ref(database, 'jobs')
onValue(jobsRef, (snapshot) => {
  const data = snapshot.val()
  store.commit('jobs/set', data)
})

// const tagsRef = ref(database, 'jobs')
// onValue(tagsRef, (snapshot) => {
//   const data = snapshot.val()
//   console.log('Got jobs')
//   console.log(data)
//   store.commit('jobs/set', data)
// })