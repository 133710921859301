import store from "@/store/index"
// import { getDatabase, ref, onValue } from 'firebase/database'
// const database = getDatabase()

// const studentsRef = ref(database, 'students/1')
// onValue(studentsRef, (snapshot) => {
//   const data = snapshot.val()
//   console.log('Got student')
//   store.commit('students/setCurrent', data)
  store.dispatch('students/fetchDegree')
// })