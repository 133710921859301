<template>
<div class="offers">
  <h2 v-if="$slots.default" class="offers_title">
    <slot/>
  </h2>
  <h2 class="offers_title" v-else-if="showTitle">Sponsored Job Offers</h2>
  <ul class="offers_list" ref="list" :class="{'offers_list-grid': grid}">
    <li v-for="job in jobs" :key="job.id" class="job">
      <JobOfferButton :job="job"/>
    </li>
  </ul>
</div>
</template>

<script>
// @ is an alias to /src
// import Icon from "@/components/Icon"
import JobOfferButton from "@/components/JobOfferButton"

export default {
  name: 'JobOfferSlider',
  components: {
    // Icon,
    JobOfferButton,
  },
  props: {
    grid: {default: true},
    jobs: {required: true},
    showTitle: {default: true},
  },
  data() {
    return {
      selected: 0,
    }
  },
  methods: {
    goNext() {
      this.selected++
      this.scrollToSelected()
    },
    goPrev() {
      this.selected--
      this.scrollToSelected()
    },
    scrollToSelected() {
      let jobs = this.$refs.list.querySelectorAll('.job')
      if( this.selected < 0) this.selected = (jobs.length - 2 + this.selected)
      if( this.selected < 0) this.selected = 0
      this.selected = this.selected % (jobs.length - 2)
      let next = jobs[this.selected]
      this.$refs.list.scroll({left: next.offsetLeft, behavior: 'smooth'})
    },
  },
  mounted() {
  },
  destroyed() {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.offers {
  --cols: 3;
  border-radius: 1.25em;
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  @include media(desktop) {
    padding: 1em;
  }
  .next, .prev {
    position: absolute;
    top: 3rem;
    bottom: 1rem;
    width: 50px;
    z-index: 20;
    transition: .2s ease-out opacity;
    display: flex;
    svg {
      width: 50%;
      height: 100%;
      margin: auto;
      fill: transparent;
      stroke: var(--color1);
    }
    &:not(:hover) {
      opacity: 0;
    }
  }
  .next {
    right: 0;
    background-image: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,1) 50%);
    svg {
      transform: rotate(180deg);
    }
  }
  .prev {
    left: 0;
    background-image: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0));
  }
  &_list {
    position: relative;
    padding: 1em;
    list-style: none;
    gap: 2rem;
    display: flex;
    overflow: auto;
    @include media(desktop) {
      padding: 0;
      flex-direction: column;
    }
    &-grid {
      @include media(desktop) {
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(var(--cols), 1fr);
      }
    }
    .job {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 1;
      flex-wrap: wrap;
      overflow: hidden;
    }
  }
  &_title {
    color: var(--color1);
    text-align: left;
    margin: 0 1em;
    @include media(desktop) {
      margin: 0;
    }
  }
}
</style>
