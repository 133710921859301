<template>
<div class="offerSnippet" @click.prevent="go">
  <img :src="logo" alt="" srcset="" class="offerSnippet_logo">
  <router-link :to="goTo" v-text="company.name" class="offerSnippet_company"></router-link>
  <router-link :to="goTo" v-text="job.title" class="offerSnippet_title"></router-link>
  <span v-text="job.location" class="offerSnippet_location"></span>
  <span v-text="job.workType" class="offerSnippet_worktype"></span>
  <ul class="offerSnippet_tags">
    <li v-for="tag in job.tags" v-text="tag" :key="tag"></li>
  </ul>
  <Icon class="delete" icon="cross" v-if="own" @click.prevent.stop="destroy"/>
</div>
</template>

<script>
import Icon from '@/components/Icon'
import {getDatabase, ref, remove} from 'firebase/database'
// @ is an alias to /src
export default {
  name: 'JobOfferButton',
  components: {
    Icon,
  },
  props: {
    job: {required: true}
  },
  data() {
    return {
    }
  },
  methods: {
    go(event) {
      if(event.target.tagName != 'A')
        this.$router.push(this.goTo)
    },
    destroy(event) {
      event.preventDefault()
      const db = getDatabase()
      remove(ref(db, `jobs/${this.job.id}`))
    },
  },
  computed: {
    logo() {
      if( this.company ) {
        const logo = this.$store.getters['companies/logo'](this.company.id)
        if( logo ) return logo.logo
      }
      return ""
    },
    goTo() {
      return {
        name: 'JobOffer',
        params: { id: this.job.id },
      }
    },
    own() {
      if( this.$store.state.userData != null ) {
        return this.$store.state.userData.companyId == this.company.id
      }
      return false
    },
    company() {
      return this.$store.getters['companies/id'](this.job.companyId)
    }
  },
}
</script>

<style lang="scss" scoped>
.offerSnippet {
  background-color: var(--color4);
  padding: 1em;
  border: 1px solid var(--color1);
  border-radius: 1em;
  overflow: hidden;
  display: grid;
  min-height: 5em;
  position: relative;
  color: var(--color1);
  grid-template-columns: min-content min-content 1fr;
  grid-template-rows: repeat(3, min-content) 1fr;
  grid-template-areas:
    "logo title title"
    "logo type type"
    "logo company location"
    "logo tags tags"
  ;
  white-space: nowrap;
  transition-property: padding, border-width;
  transition: .1s ease-out;
  &:hover {
    border-width: 3px;
    padding: calc(1em - 2px);
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  &_logo {
    grid-area: logo;
    height: 5em;
    width: 5em;
    border: 1px solid var(--color1);
    border-radius: .8em;
    margin: 0 1rem 0 0;
    object-fit: cover;
  }
  &_title {
    grid-area: title;
    font-weight: bold;
    font-size: 1.1em;
  }
  &_logo {
    grid-area: logo;
  }
  &_worktype {
    grid-area: type;
    font-weight: bold;
    font-size: 0.8em;
    color: black;
  }
  &_company {
    grid-area: company;
    font-weight: bold;
    font-size: 0.8em;
    border: solid;
    border-width: 0 2px 0 0;
    padding: 0 .2rem 0 0;
    margin: 0 .2rem 0 0;
  }
  &_location {
    grid-area: location;
    color: var(--color2);
    font-size: 0.8em;
  }
  &_tags {
    grid-area: tags;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 1em;
    font-size: 0.7rem;
    margin: auto 0 0 0;
    li {
      padding: .2em .4em .1em;
      line-height: 1;
      border-radius: 3px;
      border: 1px solid var(--color1);
    }
  }
  .delete {
    position: absolute;
    top: 1em;
    right: 1em;
    height: 1rem;
    width: 1rem;
    fill: var(--color8);
    stroke: var(--color8);
    z-index: 2000;
  }
}
</style>
