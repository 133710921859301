const messages = {
  en: {
    general: {
      actions: 'Actions',
      add: 'Add',
      cancel: 'Cancel',
      created: 'Created',
      description: 'Description',
      detail: 'Detail | Details',
      display_name: 'Display Name',
      job_offers: 'Job Offers',
      location: 'Location',
      logo: 'Logo',
      name: 'Name',
      save: 'Save',
      status: 'Status',
      update: 'Update',
      user: 'User | Users',
    },
    homepage_url: 'Homepage URL',
    smtp: {
      host: 'SMTP Host',
      password: 'SMTP Password',
      port: 'SMTP Port',
      user: 'SMTP User',
    },
  },
  pt: {
    companies: {
      add: 'Adicionar Empresa'
    },
    general: {
      actions: 'Ações',
      add: 'Adicionar',
      cancel: 'Cancelar',
      created: 'Criado',
      description: 'Descrição',
      detail: 'Detalhe | Detalhes',
      display_name: 'Nome de Exibição',
      job_offers: 'Ofertas de Emprego',
      location: 'Localização',
      logo: 'Logo',
      name: 'Nome',
      save: 'Gravar',
      status: 'Estado',
      update: 'Atualizar',
      user: 'Utilizador | Utilizadores',
    },
    homepage_url: 'URL da Página incial',
    smtp: {
      host: 'Host SMTP',
      password: 'Senha SMTP',
      port: 'Porta SMTP',
      user: 'Utilizador SMTP',
    },
  }
}

export default messages