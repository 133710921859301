import { getDatabase, ref, child, onValue } from "firebase/database";
const database = getDatabase()
const jobApplicationsRef = ref(database, 'jobApplications')

const jobs = {
  namespaced: true,
  state: {
    all: [],
    tags: [],
    jobAppRefs: [],
    jobApps: [],
  },
  mutations: {
    set(state, data) {
      if( data ) {
        let ids = Object.keys(data)
        let companies = []
        for (let i = 0; i < ids.length; i++) {
          const id = ids[i];
          let company = data[id]
          company.id = id
          companies.push(company)
        }
        state.all = companies
      } else {
        state.all = []
      }
    },
    addJobAppRef(state, {jobId, jobRef}) {
      let jobAppRefs = state.jobAppRefs
      let index = jobAppRefs.findIndex( ({id}) => id == jobId )
      if( index < 0) {
        jobAppRefs.push({id: jobId, ref: jobRef})
      }
      state.jobAppRefs = jobAppRefs
    },
    updateJobApps(state, {jobId, apps}) {
      console.log(apps)
      let jobApps = state.jobApps
      let index = jobApps.findIndex( ({id}) => id == jobId )
      if( index > -1) {
        jobApps.splice(index, 1)
      }
      if( apps ) {
        apps = Object.keys(apps).map( (id) => ({studentId: id, createdAt: apps[id].createdAt}) )
      } else {
        apps = []
      }
      jobApps.push({id: jobId, apps})
      state.jobApps = jobApps
    }
  },
  actions: {
    async fetchApplications({commit, state}, jobId) {
      let jobRef = state.jobAppRefs.find(({id}) => id == jobId)
      if( jobRef == null ) {
        jobRef = child(jobApplicationsRef, jobId)
        onValue(jobRef, (snap) => {
          commit('updateJobApps', {jobId, apps: snap.val()})
        })
        commit('addJobAppRef', {jobId, jobRef})
      }
    }
  },
  getters: {
    id: state => id => {
      return state.all.find((company) => company.id == id)
    },
    ids: state => ids => {
      return state.all.filter(({id}) => ids.includes(id) )
    },
    company: state => id => {
      return state.all.filter(({companyId}) => companyId == id)
      .sort((a,b) => a.title.localeCompare(b.title))
    },
    apps: state => id => {
      let jobApps = state.jobApps.find( (jobApps) => jobApps.id == id )
      if( jobApps ) return jobApps.apps
      return null
    }
  }
}

export default jobs
