import { getDatabase, off, onValue, ref, remove, get, child } from "firebase/database";
import { getStorage, ref as sRef, getDownloadURL } from "firebase/storage"
const storage = getStorage();
const logosRef = sRef(storage, 'companies/logos/');

const database = getDatabase()
const recruitersRef = ref(database, 'recruiters')

const companies = {
  namespaced: true,
  state: {
    all: [],
    companyUsers: [],
    companyUsersRefs: [],
    dbRef: null,
    logos: [],
    recruiters: [],
  },
  mutations: {
    addLogo(state, data) {
      state.logos.push(data)
    },
    addRecruiter(state, {info, uid}) {
      let recruiter = null
      let recruiters = state.recruiters
      let recruiterIndex = recruiters.findIndex((r) => r.uid == uid)
      if( recruiterIndex < 0 ) {
        recruiter = {uid}
      } else {
        recruiter = recruiters[recruiterIndex]
        recruiters.splice(recruiterIndex, 1)
      }
      recruiter = {...recruiter, ...info}
      recruiters.push(recruiter)
      state.recruiters = recruiters
    },
    addUsers(state, {users, companyId}) {
      if( users != null ) {
        let allUsers = state.companyUsers
        for( let uid of Object.keys(users) ) {
          console.log(users[uid])
          let user = {
            uid,
            companyId,
          }
          let index = allUsers.findIndex( (el) => el.uid == user.uid )
          if( index >= 0 ) allUsers.splice(index, 1)
          allUsers.push(user)
        }
      }
    },
    set(state, data) {
      if( data ) {
        let ids = Object.keys(data)
        let companies = []
        for (let i = 0; i < ids.length; i++) {
          const id = ids[i];
          let company = data[id]
          company.id = id
          companies.push(company)
        }
        state.all = companies
        this.dispatch('companies/fetchLogos')
      } else {
        state.all = []
      }
    },
    setCompanyUsersRef(state, data) {
      let allRefs = state.companyUsersRefs
      for (let index in allRefs) {
        let theRef = allRefs[index]
        if(theRef.id == data.id) {
          allRefs.splice(index, 1)
          break
        }
      }
      allRefs.push(data)
    },
    removeDbListener(state) {
      if( state.dbRef ) {
        off(state.dbRef)
        state.dbRef = null
      }
    },
    setDbRef(state, ref) {
      state.dbRef = ref
    },
  },
  actions: {
    async fetchRecruiter({commit, dispatch, getters}, uid) {
      try{
        let userInfo = (await get(child(recruitersRef, uid))).val()
        commit('addRecruiter', {info: userInfo, uid})
        dispatch('fetchOne', getters.recruiter(uid).companyId)
      } catch(err) {
        console.log(err)
      }
    },
    fetchOne({state, commit}, id) {
      console.log(`companies: fetching ${id}`)
      const database = getDatabase()
      commit('removeDbListener')
      commit('setDbRef', ref(database, `companies/${id}`) )
      onValue(state.dbRef, (snapshot) => {
        console.log('got companies')
        console.log(snapshot.val())
        let companies = {}
        companies[id] = snapshot.val()
        commit('set', companies)
      })
    },
    async fetchLogos({state, commit}) {
      for( let company of state.all ) {
        if( company.logo ) {
          try {
            const logoRef = sRef(logosRef, `${company.id}/${company.logo}`)
            commit('addLogo', {id: company.id, logo: await getDownloadURL(logoRef)})
          } catch(err) {
            console.log(err)
          }
        }
      }
      return "../assets/logo.png"
    },
    fetchUsers({getters, commit}, id) {
      const database = getDatabase()
      commit('setCompanyUsersRef', {
        id,
        ref: ref(database, `companyUsers/${id}`),
      })
      onValue(getters.usersRef(id).ref, (snapshot) => {
        commit('addUsers', {users: snapshot.val(), companyId: id})
      })
    },
    removeCompanies(state, companies) {
      console.log(`companies: removing companies`)
      const database = getDatabase()
      for( let i in companies ) {
        let c = companies[i]
        remove(ref(database, `companies/${c.id}`))
      }
    }
  },
  getters: {
    id: state => id => {
      return state.all.find((company) => company.id == id)
    },
    random: state => {
      return [...state.all].sort(() => 0.5 - Math.random())
    },
    users: (state, getters, rootState) => id => {
      let info = state.companyUsers.filter(({companyId}) => companyId == id)
      let uids = info.map((v) => v.uid)
      let users = rootState.users.all.filter(({uid}) => uids.includes(uid))
      return users
    },
    recruiter: state => uid => {
      return state.recruiters.find( (recruiter) => recruiter.uid == uid )
    },
    recruiterEmail: ( {all} ) => email => {
      return all.find(({emails}) => emails.includes(email))
    },
    logo: state => (id) =>  {
      return state.logos.find((logo) => logo.id == id)
    },
    usersRef: state => (id) =>  {
      return state.companyUsersRefs.find( (el) => el.id == id )
    },
    current: (state, getters, rootState) => {
      if( rootState.user && rootState.userData ) {
        if( rootState.user.token && rootState.user.token.claims.role == 'company' ) {
          return state.all.find( ({id}) => id == rootState.userData.companyId )
        }
      }
      return {name: 'Hello'}
    }
  }
}

export default companies
