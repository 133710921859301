<template>
<div class="tagsSelect">
  <ul>
    <li v-for="tag in tags" :key="tag">
      <JobOfferTag :text="tag" click="true" @click.prevent="removeTag(tag)"/>
    </li>
  </ul>
  <input type="text" :name="name" :id="id" v-model="input" @keydown="pressed">
</div>
</template>

<script>
import JobOfferTag from '@/components/JobOfferTag'
// @ is an alias to /src
export default {
  name: 'FormTextArea',
  components: {
    JobOfferTag,
  },
  props: {
    id: {required: true},
    name: {required: true},
    value: {default: []},
  },
  data() {
    return {
      input: "",
      tags: [],
    }
  },
  watch: {
    // input() {
    //   this.$emit('input', this.input)
    // },
    value() {
      if( this.value != this.tags ) {
        this.tags = this.value
        this.input = ""
      }
    },
    tags() {
      this.$emit('input', this.tags)
    },
  },
  methods: {
    addTag() {
      let input = this.input.toLowerCase()
      if( this.input.length > 2 && !this.tags.includes(input) ) {
        this.tags.push(input)
        this.tags.sort()
      }
      this.input = ""
    },
    removeTag(tag) {
      this.tags = this.tags.filter( (t) => t != tag)
    },
    pressed(e) {
      if( e.key == ',' || e.key == 'Enter') {
        e.preventDefault()
        this.addTag()
      }
    }
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
input {
  border: 2px solid var(--color6);
  border-radius : 1.5em;
  padding: .5em 1em;
  outline: none;
  color: var(--color5);
  width: 100%;
  text-transform: lowercase;
  &:focus {
    border-color: var(--color1);
  }
}
ul {
  margin: .5em 0 .5em;
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  gap: .5em;
}
</style>
