<template>
  <div class="home">
    <form action="/" @submit.prevent="submit">
      <label for="homepageUrl">{{ $t("homepage_url") }}:</label>
      <FormInput type="text" id="homepageUrl" name="homepageUrl" v-model="homepageUrl"/>

      <!-- <label for="links-domain">Links Domain</label>
      <FormInput type="password" id="links-domain" name="links-domain" v-model="linksDomain"/>
 -->
      <label for="smtp-host">{{ $t("smtp.host")}}:</label>
      <FormInput type="text" id="smtp-host" name="smtp-host" v-model="smtpHost"/>

      <label for="smtp-port">{{ $t("smtp.port")}}:</label>
      <FormInput type="text" id="smtp-port" name="smtp-port" v-model="smtpPort"/>

      <label for="smtp-user">{{ $t("smtp.user")}}:</label>
      <FormInput type="text" id="smtp-user" name="smtp-user" v-model="smtpUser"/>

      <label for="smtp-pass">{{ $t("smtp.password")}}:</label>
      <FormInput type="password" id="smtp-pass" name="smtp-pass" v-model="smtpPass"/>

      <FormButton>{{ $t("general.save") }}</FormButton>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import FormInput from "../../components/Input"
import FormButton from "../../components/Button.vue"
// import Companies from '@/components/Companies'
// import CompanyForm from '@/components/CompanyForm'

export default {
  name: 'AdminHome',
  components: {
    // Companies,
    // CompanyForm,
    FormButton,
    FormInput,
  },
  data() {
    return {
      homepageUrl: "",
      smtpHost: "",
      smtpPort: "",
      smtpUser: "",
      smtpPass: "",
      linksDomain: "",
    }
  },
  watch: {
    settings: {
      immediate: true,
      handler() {
        this.smtpHost = this.settings.SMTP_HOST
        this.smtpPort = this.settings.SMTP_PORT
        this.smtpUser = this.settings.SMTP_USER
        this.smtpPass = this.settings.SMTP_PASS
        this.homepageUrl = this.settings.HOMEPAGE_URL
        this.linksDomain = this.settings.LINKS_DOMAIN
      }
    }
  },
  methods: {
    submit() {
      this.$store.dispatch('settings/set', {setting: 'SMTP_HOST', value: this.smtpHost})
      this.$store.dispatch('settings/set', {setting: 'SMTP_PORT', value: this.smtpPort})
      this.$store.dispatch('settings/set', {setting: 'SMTP_USER', value: this.smtpUser})
      this.$store.dispatch('settings/set', {setting: 'SMTP_PASS', value: this.smtpPass})
      this.$store.dispatch('settings/set', {setting: 'HOMEPAGE_URL', value: this.homepageUrl})
      this.$store.dispatch('settings/set', {setting: 'LINKS_DOMAIN', value: this.linksDomain})
    }
  },
  computed: {
    settings() {
      return this.$store.state.settings.all
    }
  },
  created() {
    this.$store.dispatch('settings/fetch')
  }
}
</script>

<style lang="scss" scoped>
.home {
  background-color: var(--color4);
  padding: var(--gap);
  border-radius: var(--radius);
}
form {
  label {
    width: 100%;
    display: block;
  }
  input {
    display: block;
    margin-bottom: var(--gap);
    min-width: 300px;
  }
}
</style>
