<template>
  <div class="companies">
    <h2>Companies</h2>
    <div v-for="company in companies" :key="company.id">
      <router-link :to="{name: 'Company', params: {companyId: company.id} }" class="company">
        <img src="#" alt="" class="company_image">
        <span v-text="company.name" class="company_name"></span>
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Companies',
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    companies() {
      return this.$store.getters['companies/random'].slice(0, 4)
    },
    companyRouteName() {
      switch (this.role) {
        case 'admin':
          return 'AdminCompany'
        default:
          return 'Company';
      }
    },
    role() {
      if( this.user && this.user.token ) {
        return this.user.token.claims.role
      }
      return 'student'
    },
    user() {
      return this.$store.state.user
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.companies {
  max-width: 600px;
  width: 100%;
  background-color: var(--color4);
  border-radius: 1.25em;
  margin: auto;
  height: 100%;
  @include media(desktop) {
    padding: 1em;
  }
  h2 {
    margin: 0 0 1rem;
    color: var(--color1);
  }
}

.company {
  display: grid;
  grid-template-columns: 50px 1fr;
  min-height: 50px;
  gap: .5rem;
  grid-template-areas:
    "photo name"
    "photo other"
  ;
  color: var(--color3);
  text-decoration: none;
  &_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid var(--color1);
    grid-area: photo;
    border-radius: 10px;
    overflow: hidden;
  }
}
</style>
