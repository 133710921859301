<template>
<div id="app">
  <Navigation/>
  <transition name="fade">
    <router-view v-show="ready" id="view"/>
  </transition>
  <transition name="fade">
    <div id="loading" v-if="!ready">
      <img src="./assets/logo.png" alt="" srcset="">
      <div class="bar"></div>
    </div>
  </transition>
</div>
</template>

<script>
import "@/firebaseApp"
import { getAuth, signOut } from "firebase/auth";
const auth = getAuth()

// import { getFunctions, httpsCallable } from 'firebase/functions'
// const functions = getFunctions()
// const addUser = httpsCallable(functions, 'addUser')

import Navigation from "@/components/Navigation"
export default {
  components: {
    Navigation
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    ready() {
      return this.$store.state.checkedUser
    },
  },
  methods: {
    logout() {
      signOut(auth)
    }
  },
  watch: {
    user() {
      if( this.user == null ) return
      if(this.user.token.claims.role == 'company') {
        this.$store.dispatch('companies/fetchRecruiter', this.user.info.uid)
      }
    }
  },
  async mounted() {
    // console.log('app mounted')
    // try{
    //   console.log( await addUser() )
    // } catch(err) {
    //   console.log(err)
    // }
  }
}
</script>

<style lang="scss">
@import "@/assets/_queries.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

* {
  box-sizing: border-box;
}

body, html {
  padding: 0;
  margin: 0;
  $color1: #339BD3;
  $color5: #236c93;
  --color1: #{$color1};
  --color1-t1: #{rgba($color1, .75)};
  --color2: #848484;
  --color3: black;
  --color4: white;
  --color5: #{$color5};
  --color5-t1: #{rgba($color5, .75)};
  --color6: #E6E6E6;
  --color7: #d36b33; // color1: Complementary
  --color8: #d3339b; // color1: Triadic
  --color9: #9bd333; // color1: Triadic
  --color10: #334bd3; // color1: Analogous
  --color11: #33d3bb; // color1: Analogous
  --color12: #4d4d4d;
  --neo-sans: 'Neo Sans Std', Verdana, Geneva, Tahoma, sans-serif;
  --navigation-height: 3rem;
  --radius: 1.25rem;
  --max-width: 1440px;
  --gap: var(--radius);
}

#view {
  max-width: var(--max-width);
  width: 100%;
  font-size: 14px;
  padding: calc(1rem + var(--navigation-height)) 0 1rem;
  @include media(desktop) {
    padding: calc(4rem + var(--navigation-height)) 4rem 4rem;
  }
}

#loading {
  transition-delay: .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  .bar {
    height: 4px;
    width: 350px;
    max-width: 90%;
    background-color: rgba(black, .1);
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    @keyframes left-right {
      0% {
        left: 0;
        transform: translateX(0);
      }
      50% {
        left: 100%;
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
        left: 0;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      background: var(--color1);
      width: 10%;
      left: 0;
      border-radius: 2px;
      animation: left-right 2s both infinite;
    }
  }
}

#app {
  font-family: var(--neo-sans);
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;

  @include media(desktop) {
    background-color: #F2F2F2;
  }
}

#nav {
  padding: 1rem;
  background-color: white;
  filter: drop-shadow(0 2px 2px rgba(black, .2));
  margin-bottom: 4rem;
  display: flex;
  .menu {
    margin: 0 0 0 auto;
  }
  .nav_logo {
    display: block;
    height: 20px;
    img {
      height: 100%;
    }
  }
  a {
    font-weight: bold;
    // color: #2c3e50;
    &.router-link-exact-active {
      // color: #42b983;
    }
  }
}
</style>
