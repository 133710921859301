<template>
<svg class="icon" @click="$emit('click', $event)">
  <use :xlink:href="`${file}#${icon}`" :href="`${file}#${icon}`">
  </use>
</svg>
</template>

<script>
import file from "@/assets/icons.svg"
export default {
  props: {
    icon: {required: true},
  },
  computed: {
    file() {
      return file
    },
  }
}
</script>