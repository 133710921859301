<template>
  <div class="profile">
    <div class="photo"></div>
    <h1 class="name" v-text="student.name ? student.name : student.email" v-if="student"></h1>
    <h2 class="programme" v-if="degree">
       {{degree.typeName}} {{degree.name}}
    </h2>
    <!-- <div class="settings">
      <a href="" @click.prevent>
        <Icon icon="settings"/>
      </a>
    </div> -->
    <div class="cv">
      <label for="cv" v-text="cv == null ? 'Carregar CV' : 'Eliminar CV' " @click="deleteCV">
      </label>
      <input type="file" name="cv" id="cv" @change="uploadCV" ref="cv">
      <a class="cv" :href="cv.cv" target="_blank" rel="noopener noreferrer" download="true" v-if="cv">Ver CV</a>
    </div>
    <h2 class="applications_title" v-if="jobs">Candidaturas na JOBNetworkx</h2>
    <div class="applications">
      <JobOfferButton v-for="job in jobs" :key="job.id" :job="job"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JobOfferButton from "@/components/JobOfferButton"
// import Icon from "@/components/Icon"

export default {
  name: 'StudentProfile',
  components: {
    JobOfferButton,
    // Icon,
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if( this.user != null ) {
          this.$store.dispatch('students/fetchStudent', this.user.info.uid)
        }
      },
    },
    student: {
      immediate: true,
      handler() {
        if( this.student != null ) {
          this.$store.dispatch('students/fetchDegree')
        }
      },
    }
  },
  computed: {
    cv() {
      if( this.user.info ) {
        return this.$store.getters['students/cv'](this.user.info.uid)
      }
      return null
    },
    degree() {
      return this.$store.state.students.degree
    },
    jobs() {
      if( this.student != null && this.student.jobApplications != null ) {
        let ids = Object.keys(this.student.jobApplications)
        return this.$store.getters['jobs/ids'](ids)
      }
      return null
    },
    student() {
      if( this.user != null ) {
        return this.$store.state.students.current
      }
      return null
    },
    user() {
      return this.$store.state.user
    },
    role() {
      if( this.user && this.user.token ) {
        return this.user.token.claims.role
      }
      return null
    }
  },
  methods: {
    deleteCV(e) {
      if( this.cv ) {
        e.preventDefault()
        this.$store.dispatch('students/deleteCV', this.cv)
      }
    },
    uploadCV() {
      if( this.user.info ) {
        let file = this.$refs.cv.files[0]
        this.$store.dispatch('students/uploadCV', {uid: this.user.info.uid, file})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.profile {
  @include media(tablet-down) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  display: grid;
  column-gap: 1rem;
  grid-template-rows: repeat(4, min-content) 1fr;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: 
    "photo name settings"
    "photo programme ."
    "cv cv ."
    "subtitle subtitle subtitle"
    "applications applications applications"
  ;
}

.applications {
  grid-area: applications;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &_title {
    color: var(--color3);
    grid-area: subtitle;
  }
}
.cv {
  grid-area: cv;
  margin: 1rem 0 0;
  font-size: 1rem;
}
.name {
  grid-area: name;
  margin: auto 0 0 0;
}
.photo {
  height: 100px;
  width: 100px;
  background-color: var(--color2);
  border-radius: 50px;
  grid-area: photo;
}
.programme {
  grid-area: programme;
  margin: 0 0 auto 0;
  font-weight: normal;
  color: var(--color1);
}
.settings {
  grid-area: settings;
  fill: var(--color3);
  :hover {
    fill: var(--color1);
  }
  svg {
    transition: .2s ease-out fill;
    height: 1.5rem;
    width: 1.5rem;
  }
}
label {
  background-color: var(--color1);
  padding: calc(var(--gap) / 2);
  color: var(--color4);
  border-radius: var(--gap);
  line-height: 1;
}
a.cv {
  color: var(--color1);
  margin-left: calc(var(--gap) / 2);
}
input {
  display: none;
}
</style>