<template>
  <div class="companies">
    <router-view class="overlay"></router-view>
    <RouterButton :to="{name: 'NewCompany'}">{{ $t("companies.add") }}</RouterButton>
    <RouterButton :to="{name: 'CompaniesQR'}">QR</RouterButton>
    <table>
      <tr>
        <td>
          <input type="checkbox" name="" id="check_all" v-model="all_checked">
          <label class="checkbox" for="check_all" @click.prevent="check_all">
            <Icon :icon="all_checked ? 'checkbox-ok' : 'checkbox' "/>
          </label>
        </td>
        <td v-if="!any_checked">
          <Icon class="option refresh" icon="redo"/>
        </td>
        <td v-if="any_checked">
          <Icon class="option forbid" icon="forbidden"/>
        </td>
        <td v-if="any_checked" @click.prevent="removeCompanies">
          <Icon class="option forbid" icon="trash"/>
        </td>
        <td width="100%"></td>
      </tr>
    </table>
    <table>
      <thead>
        <tr>
          <th></th>
          <th class="name">{{ $t("general.display_name") }}</th>
          <th>{{ $t("general.created") }}</th>
          <th>{{ $t("general.status") }}</th>
          <th>{{ $t("general.actions") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="company, index in selected" :key="company.id" @click="go">
          <td>
            <input type="checkbox" name="" :id="`check_${company.id}`" v-model="checked[index]">
            <label class="checkbox" :for="`check_${company.id}`">
              <Icon :icon="checked[index] ? 'checkbox-ok' : 'checkbox' "/>
            </label>
          </td>
          <td width="100%">
            <router-link :to="{name: 'EditCompany', params: {companyId: company.id} }"
              v-text="company.name"
            ></router-link>
          </td>
          <td v-text="createdAt(company)"></td>
          <td><span class="ok"></span></td>
          <td>
            <Icon class="option trash" icon="trash"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import Icon from "@/components/Icon"
import RouterButton from "@/components/RouterButton"

export default {
  name: 'Companies',
  components: {
    RouterButton,
    Icon,
  },
  data() {
    return {
      users: [],
      checked: [],
    }
  },
  methods: {
    createdAt(company) {
      const createdAt = new Date(company.createdAt)
      return createdAt.toLocaleString()
    },
    check_all() {
      let all = []
      for(let i = 0; i < this.checked.length; i++) {
        all[i] = !this.all_checked
      }
      this.checked = all
    },
    go(e) {
      console.log(e.target)
      console.log(e)
    },
    removeCompanies() {
      this.$store.dispatch('companies/removeCompanies', this.checkedCompanies)
    }
  },
  computed: {
    any_checked() {
      for(let i = 0; i < this.checked.length; i++) {
        if( this.checked[i] )
          return true
      }
      return false
    },
    all_checked() {
      let check = this.checked.length > 0
      for(let i = 0; i < this.checked.length; i++) {
        check = check && this.checked[i]
      }
      return check
    },
    companies() {
      return this.$store.state.companies.all
    },
    checkedCompanies() {
      return this.selected.filter( (c,i) => this.checked[i])
    },
    selected() {
      return [...this.companies].sort((a,b) => a.name.localeCompare(b.name, 'pt',{sensitivity: 'base'}))
    },
    students() {
      return this.users.filter( (a) => a.customClaims.role == 'student' )
    },
    jobs() {
      return this.$store.state.jobs.all
    },
    user() {
      return this.$store.state.user
    },
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.checked = []
        for(let i = 0; i < this.selected.length; i++) {
          this.checked[i] = false
        }
      }
    },
    checked() {
      let all_checked = true
      for(let i = 0; i < this.checked.length; i++) {
        all_checked = all_checked && this.checked[i]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.companies {
  --gap: var(--radius);
  .overlay {
    position: fixed;
    top: var(--navigation-height);
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, var(--color1-t1), var(--color5-t1));
    display: flex;
  }

  table {
    background-color: var(--color4);
    padding: var(--gap) 0;
    &:first-of-type {
      border-top-right-radius: var(--gap);
      border-top-left-radius: var(--gap);
    }
    &:last-of-type {
      border-bottom-right-radius: var(--gap);
      border-bottom-left-radius: var(--gap);
    }
  }

  .button {
    margin: 0 0 var(--gap) var(--gap);
  }

  .option {
    height: 1rem;
    width: 1rem;
    margin: auto;
    display: block;
    &.trash, &.forbid {
      fill: var(--color8);
      stroke: var(--color8);
    }
    &.refresh {
      fill: var(--color3);
      stroke: var(--color3);
    }
  }

  .ok {
    height: .75rem;
    width: .75rem;
    background-color: var(--color9);
    display: block;
    border-radius: 50%;
    margin: auto;
  }

  input[type="checkbox"] {
    display: none;
    + .checkbox {
      display: block;
      width: 1rem;
      height: 1rem;
      margin: auto;
      fill: transparent;
      stroke: var(--color3);
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    thead {
      text-transform: uppercase;
      th.name {
        text-align: left;
      }
    }
    tbody {
      tr {
        transition: .1s ease-out background-color;
        a {
          color: var(--color3);
          text-decoration: none;
        }
        &:hover {
          background-color: var(--color6);
        }
      }
    }
    td, th {
      padding: calc(var(--gap)/2);
      vertical-align: middle;
      &:first-child {
        padding-left: var(--gap);
      }
      &:last-child {
        padding-right: var(--gap);
      }
    }
  }
}
</style>