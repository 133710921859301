import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import messages from './messages'
const i18n = new VueI18n({
  locale: 'pt', // set locale
  messages, // set locale messages
})

import 'normalize.css'
import './assets/fonts/stylesheet.css'

Vue.config.productionTip = false

import "@/firebaseApp"
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth();

import store from './store'
// import companiesListener from "@/store/listeners/companies"
import "@/store/listeners/companies"
import "@/store/listeners/jobs"
import "@/store/listeners/students"

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

onAuthStateChanged(auth, async (user) => {
  if (user) {
    app.$store.commit('setUser', user)
    if( app.$route.name == 'Login' ) {
      if( app.$router.$previous != null ) {
        app.$router.replace(app.$router.$previous)
      } else {
        app.$router.replace({name: 'Home'})
      }
    }
  } else {
    app.$store.commit('setUser', null)
    if( app.$route.name != 'Login' ) {
      app.$router.replace({query: {...app.$route.query}, name: 'Login'})
    }
  }
});
