<template>
<input :type="type" :name="name" :id="id" v-model="input">
</template>

<script>
// @ is an alias to /src
export default {
  name: 'JobOfferButton',
  components: {
  },
  props: {
    value: {default: ''},
    type: {required: true},
    id: {required: true},
    name: {required: true},
  },
  data() {
    return {
      input: "",
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if( this.value != this.input ) {
          this.input = this.value
        }
      }
    },
    input() {
      this.$emit('input', this.input)
    }
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
input {
  border: 2px solid var(--color6);
  border-radius : 2em;
  padding: .5em 1em;
  outline: none;
  color: var(--color5);
  &:focus {
    border-color: var(--color1);
  }
}
</style>
