<template>
  <div class="editCompany" v-if="company">
    <RouterButton :to="{name: 'Companies'}" class="back">Voltar</RouterButton>
    <div class="info">
      <div class="title">
        <img :src="logoUrl" alt="" srcset="" v-if="logoUrl">
        <h1 v-text="company.name"></h1>
      </div>
      <h2 class="editTitle">{{ $tc('general.detail', 2) }}</h2>
      <h2 class="usersTitle">{{ $tc('general.user', 2) }}</h2>
      <h2 class="jobsTitle">{{ $tc('general.job_offers', 2) }}</h2>
      <div class="jobs">
        <Job :job="job" v-for="job in jobs" :key="job.id"></Job>
      </div>
      <CompanyForm :company="company" class="editForm" />
      <CompanyUsersForm :company="company" class="emailsForm" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from '@/loadAxios'
import CompanyForm from '@/components/CompanyForm'
import CompanyUsersForm from '@/components/CompanyUsersForm'
import RouterButton from '@/components/RouterButton'
import Job from '@/components/Job'
import { getStorage, ref, getDownloadURL } from "firebase/storage"
const storage = getStorage();
const logosRef = ref(storage, 'companies/logos');


export default {
  name: 'Profile',
  props: {
    companyId: {required: true},
  },
  components: {
    CompanyForm,
    CompanyUsersForm,
    RouterButton,
    Job,
  },
  computed: {
    company() {
      return this.$store.getters['companies/id'](this.companyId)
    },
    jobs() {
      return this.$store.getters['jobs/company'](this.companyId)
    }
  },
  data() {
    return {
      logoUrl: "",
    }
  },
  watch: {
    company: {
      immediate: true,
      async handler() {
        if( this.company && this.company.logo ) {
          try {
            const pathRef = ref(logosRef,`${this.companyId}/${this.company.logo}`);
            this.logoUrl = await getDownloadURL(pathRef)
          } catch(err) {
            console.log(err)
          }
        }
      }
    }
  },
  created() {
    console.log(this.companyId)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.editCompany {
  .back {
    margin-bottom: var(--gap);
  }
  .title {
    color: var(--color1);
    grid-area: title;
    display: flex;
    gap: var(--gap);
    align-items: center;
    img {
      max-width: 60px;
      max-height: 60px;
    }
    h1 {
      line-height: 1;
    }
  }
  .info {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: min-content 1fr min-content;
    background-color: var(--color4);
    gap: var(--gap);
    border-radius: var(--gap);
    padding: var(--gap);
    display: grid;
    grid-template-areas: 
      "title title"
      "editTitle usersTitle"
      "form emails"
      "hr hr"
      "jobsTitle jobsTitle"
      "jobs jobs"
    ;
  }
  .jobs {
    grid-area: jobs;
    .job {
      &:not(:last-child) {
        padding-bottom: 2rem;
        border-bottom: 1px solid var(--color6);
        margin-bottom: 2rem;
      }
    }
  }
  .jobsTitle {
    grid-area: jobsTitle;
  }
  .usersTitle {
    grid-area: usersTitle;
  }
  .editTitle {
    grid-area: editTitle;
  }
  form {
    width: 100%;
    margin: 0 auto auto;

    &.editForm {
      grid-area: form;
    }

    &.emailsForm {
      grid-area: emails;
    }

    input, button {
      width: 100%;
    }
  }
}
</style>