<template>
<div class="tag" :class="{click}" @click="$emit('click', $event)">
  <span v-text="text"></span>
  <Icon icon="arrow" v-if="remove"/>
</div>
</template>

<script>
// @ is an alias to /src
import Icon from "@/components/Icon"
export default {
  name: 'JobOfferTag',
  components: {
    Icon,
  },
  props: {
    click: {default: false},
    text: {required: true},
    remove: {default: true},
  },
}
</script>

<style lang="scss" scoped>
.tag {
  font-size: 0.7rem;
  padding: .3em .4em .1em;
  line-height: 1;
  border-radius: 3px;
  border: 1px solid var(--color1);
  color: var(--color1);
  fill: var(--color1);
  display: flex;
  align-items: center;
  position: relative;
}
.icon {
  height: .8em;
  width: .8em;
  position: relative;
  top: -.1em;
}
.click {
  cursor: pointer;
}
</style>
