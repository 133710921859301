<template>
<textarea :name="name" :id="id" v-model="input">
</textarea>
</template>
<script>
// @ is an alias to /src
export default {
  name: 'FormTextArea',
  props: {
    id: {required: true},
    name: {required: true},
    value: {default: ""},
  },
  data() {
    return {
      input: "",
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if( this.value != this.input ) {
          this.input = this.value
        }
      }
    },
    input() {
      this.$emit('input', this.input)
    }
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
textarea {
  border: 2px solid var(--color6);
  border-radius : 1.5em;
  padding: .5em 1em;
  outline: none;
  color: var(--color5);
  &:focus {
    border-color: var(--color1);
  }
}
</style>
