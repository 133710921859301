<template>
<div class="select" @click="activate">
  <span v-text="selected"></span>
  <Icon icon="arrow"/>
  <ul class="options" :class="{active}">
    <li v-for="option in options" :key="option.name" v-text="option.value" @click="select(option)"></li>
  </ul>
</div>
</template>

<script>
// @ is an alias to /src
import Icon from './Icon'
export default {
  name: 'FormSelect',
  components: {
    Icon,
  },
  props: {
    value: {default: ''},
    id: {required: true},
    name: {required: true},
    placeholder: {default: 'Select'},
    options: {required: true},
  },
  data() {
    return {
      input: "",
      active: false,
    }
  },
  watch: {
    value() {
      if( this.value != this.input ) {
        this.input = this.value
      }
    },
    input() {
      this.$emit('input', this.input)
    },
  },
  methods: {
    activate(e) {
      console.log(e.target)
      this.active = !this.active
    },
    select(option) {
      this.input = option.name
    }
  },
  computed: {
    selected() {
      let option = this.options.find( ({name}) => name == this.input)
      if (option != null ) return option.value
      return this.placeholder
    }
  },
}
</script>

<style lang="scss" scoped>
.select {
  --padding-side: 1em;
  border: 2px solid var(--color6);
  border-radius : 2em;
  padding: .5em var(--padding-side);
  outline: none;
  color: var(--color5);
  background-color: var(--color4);
  display: flex;
  position: relative;
  &:focus {
    border-color: var(--color1);
  }
  select {
    display: none;
  }
  .icon {
    width: 1em;
    height: 1em;
    transform: rotate(270deg);
    margin: 0 0 0 auto;
    fill: var(--color2);
  }
  .options {
    margin: 0;
    list-style: none;
    // top: calc(100% + .5em);
    top: calc(100% - .5em);
    // top: 100%;
    border: 1px solid var(--color1);
    position: absolute;
    border-radius: 1em;
    padding: 0;
    left: var(--padding-side);
    right: var(--padding-side);
    background-color: var(--color4);
    z-index: 100;
    overflow: hidden;
    &:not(.active) {
      display: none;
    }
    li {
      padding: .5em;
      cursor: pointer;
      transition: .15s ease-out background-color;
      &:hover {
        background-color: var(--color6);
      }
    }
  }
}
</style>
