// import axios from '../../loadAxios'
import { getDatabase, ref, onValue, } from 'firebase/database'
const database = getDatabase()

import { getStorage, ref as sRef, getDownloadURL, deleteObject, uploadBytes } from "firebase/storage"
const storage = getStorage();
const cvsRef = sRef(storage, 'curriculum');

import { getFunctions, httpsCallable } from 'firebase/functions'
const functions = getFunctions()
const fenixPub = httpsCallable(functions, 'fenixPub')

const students = {
  namespaced: true,
  state: {
    all: [],
    current: {jobApplications: {}},
    degree: null,
    cvs: [],
  },
  mutations: {
    addUser(state, {uid, data}) {
      let all = state.all
      let index = all.findIndex( ({id}) => id == uid)
      if( index > -1) all.splice(index,1)
      all.push({id: uid, ...data})
      state.all = all
    },
    addCV(state, data) {
      let index = state.cvs.findIndex(({uid}) => uid == data.uid)
      if( index >= 0 ) state.cvs.splice(index, 1)
      state.cvs.push(data)
    },
    remCV(state, cv) {
      let index = state.cvs.findIndex(({uid}) => uid == cv.uid)
      if( index >= 0 ) state.cvs.splice(index, 1)
    },
    set(state, data) {
      if( data ) {
        let ids = Object.keys(data)
        let companies = []
        for (let i = 0; i < ids.length; i++) {
          const id = ids[i];
          let company = data[id]
          company.id = id
          companies.push(company)
        }
        state.all = companies
      } else {
        state.all = []
      }
    },
    setCurrent(state, data) {
      state.current = data
    },
    setDegree(state, data) {
      state.degree = data
    },
  },
  actions: {
    async fetchDegree({state, commit}) {
      if( state.current != null && state.degree == null ) {
        console.log(state.current.degree)
        const res = await fenixPub(state.current.degree)
        console.log(res)
        commit('setDegree', res)
        // axios.get(`${process.env.VUE_APP_API_HOST}/fenixPub/degrees/${state.current.degree}`)
        // .then( (res) => commit('setDegree', res.data ) )
      }
    },
    async uploadCV({commit}, data) {
      let file = data.file
      let fileRef = sRef(cvsRef, `${data.uid}/cv.pdf`)
      await uploadBytes(fileRef, file)
      let cv = await getDownloadURL(fileRef)
      commit('addCV', {uid: data.uid, cv})
    },
    async deleteCV({commit}, cv) {
      const cvRef = sRef(cvsRef, `${cv.uid}/cv.pdf`)
      try {
        await deleteObject(cvRef)
        commit('remCV', cv)
      } catch(err) {
        console.log(err)
      }
    },
    fetchStudent({commit}, uid) {
      const studentsRef = ref(database, `students/${uid}`)
      console.log(`listening students/${uid}`)
      onValue(studentsRef, async (snapshot) => {
        if( snapshot.exists() ) {
          const data = snapshot.val()
          const cvRef = sRef(cvsRef, `${uid}/cv.pdf`)
          try {
            let cv = await getDownloadURL(cvRef)
            commit('addCV', {uid, cv})
          } catch(err) {
            console.log(err)
          }
          commit('addUser', {uid, data})
          commit('setCurrent', data)
        }
      })
    }
  },
  getters: {
    id: state => id => {
      return state.all.find((company) => company.id == id)
    },
    company: state => id => {
      return state.all.filter(({companyId}) => companyId == id)
    },
    cv: state => uid => {
      return state.cvs.find( cv => cv.uid == uid )
    },
    cvs: state => uids => {
      return state.cvs.filter( cv => uids.includes(cv.uid) )
    },
    ids: state => ids => {
      return state.all.filter( ({id}) => ids.includes(id) )
    }
  }
}

export default students
