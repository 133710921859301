<template>
  <div class="home">
    <!-- <Job :id="jobs[0].id" v-if="jobs.length" /> -->
    <h1 v-text="company.name"></h1>
    <div class="menu">
      <router-button :to="{name: 'JobsNew'}">Criar Oferta</router-button>
    </div>
    <JobOffersSection :jobs="promoted" v-if="promoted.length">
      Ofertas Promovidas
    </JobOffersSection>
    <JobOffersSection :jobs="jobs">
      Ofertas
    </JobOffersSection>
  </div>
</template>

<script>
// @ is an alias to /src
import JobOffersSection from '@/components/JobOffersSection'
import RouterButton from '@/components/RouterButton.vue'
// import Job from "@/views/company/Job"

export default {
  name: 'CompanyHome',
  components: {
    // Job,
    JobOffersSection,
    RouterButton,
  },
  computed: {
    jobs() {
      if( this.company ) {
        return this.$store.getters['jobs/company'](this.company.id).filter( ({promoted}) => !promoted)
      }
      return []
    },
    promoted() {
      if( this.company ) {
        return this.$store.getters['jobs/company'](this.company.id).filter( ({promoted}) => promoted)
      }
      return []
    },
    user() {
      return this.$store.state.user
    },
    userData() {
      return this.$store.state.userData
    },
    email() {
      if( this.user ) {
        return this.user.info.email
      }
      return null
    },
    recruiter() {
      if( this.user && this.user.info ) {
        return this.$store.getters['companies/recruiter'](this.user.info.uid)
      }
      return null
    },
    company() {
      if( this.recruiter ) {
        return this.$store.getters['companies/id'](this.recruiter.companyId)
      }
      return null
    },
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        console.log(this.user)
        if( this.user != null && this.user.info && this.company == null ) {
          this.$store.dispatch('companies/fetchRecruiter', this.user.info.uid)
        }
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  margin: auto;
  color: var(--color2);
}
.home {
  .offers {
    margin-top: 1rem;
  }
  .menu {
    margin-bottom: 1rem;
  }
}
</style>