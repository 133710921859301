import { getFunctions, httpsCallable } from 'firebase/functions'
const functions = getFunctions()
const getUsers = httpsCallable(functions, 'users')

const users = {
  namespaced: true,
  state: {
    all: [],
    fetched: false,
    current: null,
  },
  mutations: {
  },
  actions: {
    async fetch({state, dispatch}) {
      if( state.fetched ) return
      state.fetched = true
      dispatch('refresh')
    },
    async refresh({state}) {
      console.log('refreshing users')
      try {
        let res = await getUsers()
        state.all = res.data
      } catch(err) {
        console.log(err)
        state.fetched = false
      }
    }
  },
  getters: {
  }
}

export default users
