<template>
  <div class="companiesQR">
    <ul>
      <li v-for="company in jobs" :key="company.id">
        <h3>{{ company.title }}</h3>
        <img :src="company.qr" alt="" srcset="">
      </li>
    </ul>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from 'firebase/functions'
const functions = getFunctions()
const generateQR = httpsCallable(functions, 'company-qr')

export default {
  name: 'CompaniesQR',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    companies() {
      return this.$store.state.companies.all
    },
    jobs() {
      return this.$store.state.jobs.all
    },
  },
  watch: {
  },
  mounted() {
    generateQR()
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  h3 {
    margin-bottom: 0;
  }
}
</style>