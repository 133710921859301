<template>
<div class="categories">
  <h2 class="categories_title">Categories</h2>
  <ul class="categories_list" ref="list">
    <li v-for="cat in categories" :key="cat" class="category">
      {{ cat }}
    </li>
  </ul>
</div>
</template>

<script>
// @ is an alias to /src
// import Icon from "@/components/Icon"
// import JobOfferButton from "@/components/JobOfferButton"

export default {
  name: 'JobOfferCategories',
  components: {
    // Icon,
    // OfferButton,
  },
  props: {
  },
  data() {
    return {
      categories: [
        "Software Developer",
        "Project Manager",
        "Engenheiro de Redes",
        "Engenheiro de Redes",
        "Engenheiro Aeroespacial",
        "Engenheiro Aeroespacial",
      ]
    }
  },
  methods: {
  },
  mounted() {
  },
  destroyed() {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.categories {
  --cols: 2;
  border-radius: 1.25em;
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  @include media(desktop) {
    padding: 1em;
  }
  &_list {
    position: relative;
    padding: 1em;
    list-style: none;
    gap: 1rem;
    display: flex;
    overflow: auto;
    @include media(desktop) {
      padding: 0;
      overflow: hidden;
      display: grid;
      grid-template-columns: repeat(var(--cols), 1fr);
    }
    .category {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 1;
      flex-wrap: wrap;
      overflow: hidden;
      border: 1px solid var(--color1);
      padding: .5em;
      border-radius : 5px;
    }
  }
  &_title {
    color: var(--color1);
    text-align: left;
    margin: 0 1em;
    @include media(desktop) {
      margin: 0;
    }
  }
}
</style>
