<template>
<div class="nav" v-show="ready">
  <router-link to="/" class="nav_logo">
    <img alt="Job Network Logo" src="../assets/logo_no_symbol.png">
  </router-link>
  <Search v-if="user"></Search>
  <div class="notice" v-if="notice">
    <router-link :to="{name: 'Profile'}">{{ notice }}</router-link>
  </div>
  <div class="menu" v-if="user">
    <router-link :to="{name: 'Profile'}" v-if="role != 'admin'"/>
    <router-link :to="{name: 'Profile'}" v-if="role == 'student'">
      <Icon icon="profile"/>
    </router-link>
    <router-link :to="{name: 'Companies'}" v-if="role == 'admin'">
      <Icon icon="companies"/>
    </router-link>
    <router-link :to="{name: 'Jobs'}" v-if="role != 'admin' && role == 'student'">
      <Icon icon="jobs"/>
    </router-link>
    <a href="#" @click.prevent="logout">Sair</a>
  </div>
</div>
</template>

<script>
import "@/firebaseApp"
import { getAuth, signOut } from "firebase/auth";
const auth = getAuth()

import Search from '@/components/Search'
import Icon from '@/components/Icon'
export default {
  components: {
    Search,
    Icon,
  },
  computed: {
    notice() {
      if( this.role == 'student' ) {
        let cv = this.$store.getters['students/cv'](this.user.info.uid)
        if( cv == null ) {
          return "Ainda não fizeste upload do teu CV"
        }
      }
      return null
    },
    role() {
      if( this.token ) return this.token.claims.role
      return null
    },
    token() {
      if( this.user ) return this.user.token
      return null
    },
    user() {
      return this.$store.state.user
    },
    ready() {
      return this.$store.state.checkedUser
    },
  },
  methods: {
    logout() {
      signOut(auth)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";
@import "@/assets/_animations.scss";

.nav {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  height: var(--navigation-height);
  background-color: white;
  filter: drop-shadow(0 2px 2px rgba(black, .2));
  display: grid;
  grid-template-areas: "logo search menu";
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  overflow: hidden;
  .notice {
    grid-area: search;
    text-align: center;
    background-color: var(--color8);
    padding: calc(var(--gap)/2);
    border-radius: var(--gap);
    a {
      color: var(--color4);
      text-decoration: none;
    }
  }
  .search {
    grid-area: search;
    height: 1.5rem;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    @include media(tablet-down) {
      display: none;
    }
  }
  .menu {
    grid-area: menu;
    margin: 0 0 0 auto;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    a {
      display: block;
      max-height: 100%;
      &:not(:hover) {
        opacity: .2;
      }
      &.router-link-active {
        opacity: 1;
        position: relative;
        &::after {
          animation: fade .4s ease-out;
          background-color: var(--color1);
          position: absolute;
          content: '';
          bottom: 9px;
          left: 0;
          right: 0;
          height: 4px;
          width: 4px;
          margin: 0 auto;
          border-radius: 4px;
        }
      }
    }
    svg {
      height: calc(var(--navigation-height));
      width: calc(var(--navigation-height));
    }
  }
  .nav_logo {
    grid-area: logo;
    display: block;
    height: 20px;
    img {
      height: 100%;
    }
  }
  a {
    font-weight: bold;
    // color: #2c3e50;
    fill: var(--color1);
    stroke: var(--color1);
    color: var(--color1);
    transition: .2s ease-out;
    transition-property: fill, opacity;
  }
}
</style>
