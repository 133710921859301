<template>
<div class="job">
  <a href="#" class="close">
    <Icon icon="cross" @click.prevent="goBack"/>
  </a>
  <h2 v-text="job.title" class="title"></h2>
  <img :src="logo" alt="" class="logo">
  <div class="companyInfo">
    <h4 v-text="company.name" class="company"></h4>
    <h4 v-text="job.location" class="location"></h4>
  </div>
  <ul class="tags">
    <li v-for="tag in job.tags" v-text="tag" :key="tag"></li>
  </ul>
  <div class="description">
    <h3>Descrição:</h3>
    <p v-html="job.description"></p>
  </div>
  <div class="buttons">
    <!-- <ClickButton @click.prevent="apply">
      {{ applyText }}
    </ClickButton> -->
  </div>
  <div class="applications" v-if="students.length">
    <h4>Candidaturas</h4>
    <ul>
      <li v-for="student in students" :key="student.id">
        <strong v-text="student.name"></strong>:
        <a :href="student.cv" target="_blank" rel="noopener noreferrer">Ver CV</a>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
// import ClickButton from "@/components/ClickButton"
import Icon from "@/components/Icon"
import {firebaseApp} from '@/firebaseApp'
import {getDatabase, set, ref, serverTimestamp} from 'firebase/database'
import defaultLogo from '@/assets/logo.png'

export default {
  name: 'CompanyJob',
  props: {
    id: {required: true},
  },
  components: {
    // ClickButton,
    Icon,
  },
  computed: {
    cvs() {
      return this.$store.getters['students/cvs'](this.applications.map(({studentId}) => studentId))
    },
    students() {
      let students = this.$store.getters['students/ids'](this.cvs.map( ({uid}) => uid ))
      let merged = []
      for( let student of students ) {
        let cv = this.cvs.find(({uid}) => uid == student.id)
        console.log(cv)
        merged.push({...student, ...cv})
      }
      return merged.sort((a,b) => {
        if( a.name && b.name ) return a.name.localeCompare(b.name)
        return 0
      })
    },
    applications() {
      let apps = this.$store.getters['jobs/apps'](this.id)
      if( apps == null ) return []
      return apps
    },
    applyText() {
      if( this.promoted ) return "Não promover"
      return "Promover"
    },
    company() {
      if( this.job ) {
        return this.$store.getters['companies/id'](this.job.companyId)
      }
      return null
    },
    job() {
      return this.$store.getters['jobs/id'](this.id)
    },
    logo() {
      if( this.company ) {
        let logo = this.$store.getters['companies/logo'](this.company.id)
        if(logo) return logo.logo
      }
      return defaultLogo
    },
    user() {
      return this.$store.state.user
    },
    role() {
      if( this.user && this.user.token ) {
        return this.user.token.claims.role
      }
      return null
    },
    promoted() {
      if( this.job.promoted ) return this.job.promoted
      return false
    },
  },
  watch: {
    applications: {
      immediate: true,
      handler() {
        for( let i of this.applications ) {
          this.$store.dispatch('students/fetchStudent', i.studentId)
        }
      }
    }
  },
  methods: {
    apply() {
      const db = getDatabase(firebaseApp)
      console.log(serverTimestamp())
      set(ref(db, `jobs/${this.job.id}/promoted`), !this.promoted)
    },
    goBack() {
      if( this.$router.$previous.name != null ) {
        this.$router.push(this.$router.$previous)
      } else {
        this.$router.push({name: 'Home'})
      }
    },
  },
  mounted() {
    this.$store.dispatch('jobs/fetchApplications', this.id)
  }
}
</script>

<style lang="scss" scoped>
.job {
  --gap: 1rem;
  background-color: var(--color4);
  border-radius: var(--radius);
  padding: var(--gap);
  display: grid;
  gap: var(--gap);
  grid-template-columns: min-content 1fr;
  grid-template-rows: repeat(4, min-content) 1fr min-content;
  grid-template-areas:
    "title title"
    "logo info"
    "tags tags"
    "description description"
    "buttons buttons"
    "applications applications"
  ;
  max-width: 800px;
  margin: auto;

  .close {
    display: none;
  }

  .applications {
    grid-area: applications;
  }

  .title {
    grid-area: title;
    color: var(--color1);
    margin: 0;
  }
  .logo {
    grid-area: logo;
    height: 75px;
    width: 75px;
    border: 1px solid var(--color1);
    border-radius: var(--radius);
  }
  .companyInfo {
    grid-area: info;
    height: 100%;
  }
  .company {
    margin: auto 0;
  }
  .location {
    margin: auto 0;
    font-weight: normal;
    color: var(--color1);
  }
  .tags {
    grid-area: tags;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 1em;
    font-size: 0.7rem;
    margin: auto 0 0 0;
    li {
      padding: .2em .4em .1em;
      line-height: 1;
      border-radius: 3px;
      border: 1px solid var(--color1);
      color: var(--color1);
    }
  }
  .description {
    grid-area: description;
    h3 {
      margin-top: 0;
    }
  }
  .buttons {
    grid-area: buttons;
    display: flex;
    gap: var(--gap);
    justify-content: center;
  }
}
</style>