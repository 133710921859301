<template>
  <div class="profile" v-if="company">
    <div class="photo"></div>
    <h1 class="name" v-text="company.name"></h1>
    <div class="cv">
      <ClickButton>
        Upload CV
      </ClickButton>
    </div>
    <h2 class="applications_title">Job application history</h2>
    <div class="applications">
      <JobOfferButton v-for="job in jobs" :key="job.id" :job="job"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JobOfferButton from "@/components/JobOfferButton"
import ClickButton from "@/components/ClickButton"

export default {
  name: 'Profile',
  components: {
    JobOfferButton,
    ClickButton,
  },
  computed: {
    company() {
      return this.$store.getters['companies/current']
    },
    jobs() {
      return this.$store.state.jobs.all
    },
    user() {
      return this.$store.state.user
    },
    role() {
      if( this.user && this.user.token ) {
        return this.user.token.claims.role
      }
      return null
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.profile {
  @include media(tablet-down) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  display: grid;
  column-gap: 1rem;
  grid-template-rows: repeat(4, min-content) 1fr;
  grid-template-columns: min-content 1fr;
  grid-template-areas: 
    "photo name"
    "photo programme"
    "cv cv"
    "subtitle subtitle"
    "applications applications"
  ;
}

.applications {
  grid-area: applications;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &_title {
    color: var(--color3);
    grid-area: subtitle;
  }
}
.cv {
  grid-area: cv;
  margin: 1rem 0 0;
  font-size: 1rem;
}
.name {
  grid-area: name;
  margin: auto 0 0 0;
}
.photo {
  height: 100px;
  width: 100px;
  background-color: var(--color2);
  border-radius: 50px;
  grid-area: photo;
}
.programme {
  grid-area: programme;
  margin: 0 0 auto 0;
  font-weight: normal;
  color: var(--color1);
}
</style>