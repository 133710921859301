<template>
  <div class="home">
    <router-view name="admin" v-if="role == 'admin'"></router-view>
    <router-view name="company" v-if="role == 'company'"></router-view>
    <router-view name="student" v-if="role == 'student'"></router-view>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    role() {
      if( this.user && this.user.token ) {
        return this.user.token.claims.role
      }
      return null
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     if( vm.user == null ) {
  //       vm.$router.replace({name: 'Login'})
  //     }
  //   })
  // },
}
</script>


<style lang="scss" scoped>
.home {
  margin: auto;
}
</style>