<template>
  <div class="profile">
    <!-- <ClickButton>Adicionar Conta de Admin</ClickButton> -->
    <RouterButton :to="{name: 'NewCompany'}">Adicionar Conta de Empresa</RouterButton>
    <table>
      <tr>
        <td>
          <input type="checkbox" name="" id="check_all" v-model="all_checked">
          <label class="checkbox" for="check_all" @click.prevent="check_all">
            <Icon :icon="all_checked ? 'checkbox-ok' : 'checkbox' "/>
          </label>
        </td>
        <td v-if="!any_checked">
          <Icon class="option refresh" icon="redo"/>
        </td>
        <td v-if="any_checked">
          <Icon class="option forbid" icon="forbidden"/>
        </td>
        <td width="100%"></td>
      </tr>
    </table>
    <table>
      <thead>
        <tr>
          <th></th>
          <th class="name">Display Name</th>
          <th>Created</th>
          <th>Role</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user, index in selected" :key="user.uid">
          <td>
            <input type="checkbox" name="" :id="`check_${user.uid}`" v-model="checked[index]">
            <label class="checkbox" :for="`check_${user.uid}`">
              <Icon :icon="checked[index] ? 'checkbox-ok' : 'checkbox' "/>
            </label>
          </td>
          <td width="100%" v-text="user.displayName"></td>
          <td v-text="createdAt(user)"></td>
          <td style="text-align: center" v-text="user.customClaims.role"></td>
          <td><span class="ok"></span></td>
          <td>
            <Icon class="option trash" icon="trash"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from '@/loadAxios'
import Icon from "@/components/Icon"
// import ClickButton from "@/components/ClickButton"
import RouterButton from "@/components/RouterButton"

export default {
  name: 'Profile',
  components: {
    // ClickButton,
    RouterButton,
    Icon,
  },
  data() {
    return {
      users: [],
      checked: [],
    }
  },
  methods: {
    createdAt(user) {
      const createdAt = new Date(user.metadata.creationTime)
      return createdAt.toLocaleString()
    },
    check_all() {
      let all = []
      for(let i = 0; i < this.checked.length; i++) {
        all[i] = !this.all_checked
      }
      this.checked = all
    },
  },
  computed: {
    any_checked() {
      for(let i = 0; i < this.checked.length; i++) {
        if( this.checked[i] )
          return true
      }
      return false
    },
    all_checked() {
      let check = this.checked.length > 0
      for(let i = 0; i < this.checked.length; i++) {
        check = check && this.checked[i]
      }
      return check
    },
    selected() {
      return this.users
    },
    students() {
      return this.users.filter( (a) => a.customClaims.role == 'student' )
    },
    jobs() {
      return this.$store.state.jobs.all
    },
    user() {
      return this.$store.state.user
    },
    role() {
      if( this.user && this.user.token ) {
        return this.user.token.claims.role
      }
      return null
    },
  },
  watch: {
    selected() {
      this.checked = []
      for(let i = 0; i < this.selected.length; i++) {
        this.checked[i] = false
      }
    },
    checked() {
      let all_checked = true
      for(let i = 0; i < this.checked.length; i++) {
        all_checked = all_checked && this.checked[i]
      }
    },
  },
  created() {
    this.$store.dispatch('users/fetch')
    // axios.get(`${process.env.VUE_APP_API_HOST}/users/`)
    // .then( (res) => {
    //   this.users = res.data.users 
    // })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.profile {
  table {
    background-color: var(--color4);
    padding: var(--gap) 0;
    &:first-of-type {
      border-top-right-radius: var(--gap);
      border-top-left-radius: var(--gap);
    }
    &:last-of-type {
      border-bottom-right-radius: var(--gap);
      border-bottom-left-radius: var(--gap);
    }
  }

  .button {
    margin: 0 0 var(--gap) var(--gap);
  }

  .option {
    height: 1rem;
    width: 1rem;
    margin: auto;
    display: block;
    &.trash, &.forbid {
      fill: var(--color8);
      stroke: var(--color8);
    }
    &.refresh {
      fill: var(--color3);
      stroke: var(--color3);
    }
  }

  .ok {
    height: .75rem;
    width: .75rem;
    background-color: var(--color9);
    display: block;
    border-radius: 50%;
    margin: auto;
  }

  input[type="checkbox"] {
    display: none;
    + .checkbox {
      display: block;
      width: 1rem;
      height: 1rem;
      margin: auto;
      fill: transparent;
      stroke: var(--color3);
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    thead {
      text-transform: uppercase;
      th.name {
        text-align: left;
      }
    }
    tbody {
      tr {
        transition: .1s ease-out background-color;
        &:hover {
          background-color: var(--color6);
        }
      }
    }
    td, th {
      padding: calc(var(--gap)/2);
      vertical-align: middle;
      &:first-child {
        padding-left: var(--gap);
      }
      &:last-child {
        padding-right: var(--gap);
      }
    }
  }
}
</style>