import { getDatabase, onValue, ref, set, child } from "firebase/database";

const database = getDatabase()
const settingsRef = ref(database, 'settings')

const companies = {
  namespaced: true,
  state: {
    listening: false,
    all: {},
  },
  mutations: {
    listening(state, l) {
      state.listening = l
    },
    settings(state, s) {
      state.all = s
    }
  },
  actions: {
    set(store, {setting, value} ) {
      set(child(settingsRef, setting), value)
    },
    fetch({state, commit}) {
      if( !state.listening ) {
        commit('listening', true)
        onValue(settingsRef, (snapshot) => {
          console.log('GOT SETTINGS')
          const data = snapshot.val()
          console.log(data)
          commit('settings', data)
        })
      }
    },
  },
  getters: {
  }
}

export default companies
