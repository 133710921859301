<template>
  <button type="submit" @click="$emit('click', $event)"><slot/></button>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Button',
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
button {
  border: none;
  background-color: var(--color1);
  padding: .4em 1em .25em;
  border-radius: 4em;
  text-decoration: none;
  transition: .2s ease-out;
  transition-property: background-color, transform;
  color: var(--color4);
  display: inline-block;
  &:hover {
    background-color: var(--color5);
  }
  &:active {
    transition-duration: .1s;
    transform: scale(.95);
  }
  &:focus-visible {
    outline-offset: .2em;
    outline-color: var(--color3);
  }
  &.error {
    background-color: var(--color8);
  }
}
</style>
