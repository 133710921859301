<template>
  <div class="jobsNew" v-if="company">
    <h1>Criar Nova Oferta</h1>
    <JobOfferForm/>
  </div>
</template>

<script>
// @ is an alias to /src
import JobOfferForm from '@/components/JobOfferForm'

export default {
  name: 'CompanyNewJob',
  components: {
    JobOfferForm,
  },
  computed: {
    jobs() {
      if( this.company ) {
        return this.$store.getters['jobs/company'](this.company.id)
      }
      return []
    },
    user() {
      return this.$store.state.user
    },
    userData() {
      return this.$store.state.userData
    },
    email() {
      if( this.user ) {
        return this.user.info.email
      }
      return null
    },
    company() {
      return this.$store.getters['companies/current']
    },
  },
  watch: {
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
}
.jobsNew {
  margin: auto;
  min-height: 100vh;
  max-width: var(--max-width);
  width: 100%;
}
</style>