<template>
<div class="job" v-if="job" :class={applied}>
  <a href="#" class="close">
    <Icon icon="cross" @click.prevent="goBack"/>
  </a>
  <h2 v-text="job.title" class="title"></h2>
  <img :src="logo" alt="" class="logo">
  <div class="companyInfo">
    <h4 v-text="company.name" class="company"></h4>
    <h4 v-text="job.location" class="location"></h4>
  </div>
  <ul class="tags">
    <li v-for="tag in job.tags" v-text="tag" :key="tag"></li>
  </ul>
  <div class="description">
    <h3>Descrição:</h3>
    <p v-html="job.description"></p>
  </div>
  <div class="disclaimer" v-if="applied">
    <p>Estas agora a partilhar os teus dados com "{{company.name}}".</p>
  </div>
  <div class="buttons">
    <ClickButton @click.prevent="apply(false)" v-text="applyText">Apply</ClickButton>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import ClickButton from "@/components/ClickButton"
import Icon from "@/components/Icon"
import {firebaseApp} from '@/firebaseApp'
import {getDatabase, set, ref, remove, serverTimestamp } from 'firebase/database'
import defaultLogo from '@/assets/logo.png'

export default {
  name: 'StudentJob',
  props: {
    id: {required: true},
  },
  components: {
    ClickButton,
    Icon,
  },
  computed: {
    logo() {
      if( this.company ) {
        let logo = this.$store.getters['companies/logo'](this.company.id)
        if(logo) return logo.logo
      }
      return defaultLogo
    },
    applyText() {
      if( this.applied ) return 'Remover candidatura'
      return 'Apply'
    },
    applied() {
      if( this.student && this.student.jobApplications != null ) {
        return Object.keys(this.student.jobApplications).includes(this.id)
      }
      return false
    },
    company() {
      if( this.job ) {
        return this.$store.getters['companies/id'](this.job.companyId)
      }
      return null
    },
    student() {
      if( this.user != null ) {
        return this.$store.state.students.current
      }
      return null
    },
    user() {
      return this.$store.state.user
    },
    job() {
      return this.$store.getters['jobs/id'](this.id)
    },
  },
  methods: {
    apply(force = false) {
      const db = getDatabase(firebaseApp)
      let dbRef = ref(db, `students/${this.user.info.uid}/jobApplications/${this.job.id}`) 
      let appRef = ref(db, `jobApplications/${this.job.id}/${this.user.info.uid}`)
      if( this.student.jobApplications == null || !Object.keys(this.student.jobApplications).includes(this.id) ) {
        console.log('add application')
        set(dbRef, {
          created_at: serverTimestamp(),
        });
        set(appRef, {
          createdAt: serverTimestamp(),
        })
      } else if( !force ) {
        console.log('remove application')
        remove(dbRef)
        remove(appRef)
      }
    },
    goBack() {
      if( this.$router.$previous.name != null ) {
        this.$router.push(this.$router.$previous)
      } else {
        this.$router.push({name: 'Home'})
      }
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if( this.user.info ) {
          this.$store.dispatch('students/fetchStudent', this.user.info.uid)
        }
      }
    },
    job: {
      immediate: true,
      handler() {
        if( this.job && this.student && this.$route.query.method == 'qr' ) {
          this.apply(true)
        }
      }
    },
    student: {
      immediate: true,
      handler() {
        if( this.job && this.student && this.$route.query.method == 'qr' ) {
          this.apply(true)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.job {
  --gap: 1rem;
  background-color: var(--color4);
  border-radius: var(--radius);
  padding: var(--gap);
  display: grid;
  gap: var(--gap);
  grid-template-columns: min-content 1fr;
  grid-template-rows: repeat(4, min-content) 1fr min-content;
  grid-template-areas:
    "title title"
    "logo info"
    "tags tags"
    "description description"
    "buttons buttons"
  ;
  max-width: 800px;
  margin: auto;
  &.applied {
    grid-template-rows: repeat(5, min-content) min-content;
    grid-template-areas:
      "title title"
      "logo info"
      "tags tags"
      "description description"
      "disclaimer disclaimer"
      "buttons buttons"
  ;
  }

  .disclaimer {
    grid-area: disclaimer;
    background-color: var(--color2);
    text-align: center;
    font-size: .8em;
    color: var(--color4);
    width: fit-content;
    padding: calc(var(--gap) /2);
    line-height: 1;
    margin: auto;
    p {
      margin: 0;
    }
  }

  .close {
    display: none;
  }

  .title {
    grid-area: title;
    color: var(--color1);
    margin: 0;
  }
  .logo {
    grid-area: logo;
    height: 75px;
    width: 75px;
    border: 1px solid var(--color1);
    border-radius: var(--radius);
  }
  .companyInfo {
    grid-area: info;
    height: 100%;
  }
  .company {
    margin: auto 0;
  }
  .location {
    margin: auto 0;
    font-weight: normal;
    color: var(--color1);
  }
  .tags {
    grid-area: tags;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 1em;
    font-size: 0.7rem;
    margin: auto 0 0 0;
    li {
      padding: .2em .4em .1em;
      line-height: 1;
      border-radius: 3px;
      border: 1px solid var(--color1);
      color: var(--color1);
    }
  }
  .description {
    grid-area: description;
    h3 {
      margin-top: 0;
    }
  }
  .buttons {
    grid-area: buttons;
    display: flex;
    gap: var(--gap);
    justify-content: center;
  }
}
</style>