import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// import { getDatabase, ref, onValue, off } from 'firebase/database'

import companies from "@/store/modules/companies"
import jobs from "@/store/modules/jobs"
import settings from "@/store/modules/settings"
import students from "@/store/modules/students"
import users from "@/store/modules/users"

const store = new Vuex.Store({
  state: {
    user: null,
    userData: null,
    checkedUser: false,
  },
  mutations: {
    async setUser(state, user) {
      if( user == null ) {
        state.user = null
        state.checkedUser = true
        return
      }
      try {
        const res = await user.getIdTokenResult()
        state.user = {info: user, token: res}
      } catch(err) {
        console.log(err)
      }
      state.checkedUser = true
    },
    setUserData(state, data) {
      state.userData = data
    },
    setUserRef(state, ref) {
      state.userRef = ref
    },
  },
  actions: {
  },
  modules: {
    companies,
    jobs,
    settings,
    students,
    users,
  }
})

export default store
