import store from "@/store/index"
import {firebaseApp} from "../../firebaseApp"
import { getDatabase, ref, onValue, } from 'firebase/database'
// import { getDatabase, ref, onValue, orderByChild } from 'firebase/database'
const database = getDatabase(firebaseApp)

const companiesRef = ref(database, 'companies')
onValue(companiesRef, (snapshot) => {
  const data = snapshot.val()
  console.log('Got companies')
  store.commit('companies/set', data)
})