<template>
  <div class="search" v-if="user == null">
    <JobOffersSection :jobs="foundJobs" class="search_recent">
      <span v-for="t in terms" v-text='t' :key="t"></span>
    </JobOffersSection>
  </div>
</template>

<script>
// @ is an alias to /src
import JobOffersSection from "@/components/JobOffersSection"

function wordSimilarity(a,b) {
  const comparator = new Intl.Collator("pt", {sensitivity: 'base'})
  let similar = 0
  for(let i = 0; i < a.length || i < b.length; i++) {
    if( comparator.compare( a[i], b[i]) == 0) {
      similar++
    }
  }
  return similar;
}

export default {
  name: 'StudentSearch',
  components: {
    JobOffersSection,
  },
  props: {
    term: {required: true},
  },
  computed: {
    terms() {
      return this.term.split(" ")
    },
    tagsOrder() {
      let all = this.$store.state.jobs.all
      let jobs = []
      for( let i = 0; i < all.length; i++) {
        let job = all[i]
        let common = 0
        for( let j = 0; j < job.tags.length; j++ ) {
          let tag = job.tags[j]
          if( this.terms.includes(tag) ) common++
        }
        jobs.push({count: common, id: job.id})
      }
      jobs = jobs.filter(({count}) => count > 0)
      return jobs.sort((a,b) => b.count - a.count)
    },
    tagJobs() {
      let all = this.$store.state.jobs.all
      let jobs = []
      for( let i = 0; i < this.tagsOrder.length; i++) {
        jobs.push(all.find(({id}) => id == this.tagsOrder[i].id))
      }
      return jobs
    },
    titleSimilarity() {
      let all = this.$store.state.jobs.all
      let jobs = []
      for( let i = 0; i < all.length; i++) {
        let job = all[i]
        let similarity = 0
        let title = job.title.split(" ")
        for( let j = 0; j < title.length; j++) {
          if( this.terms.includes(title[j]) ) {
            similarity += title[j].length
          } else {
            similarity += this.compareToTerms(title[j])
          }
        }
        jobs.push( {similarity ,job} )
      }
      return jobs.sort( (a,b) => b.similarity - a.similarity )
    },
    foundJobs() {
      let similarJobs = []
      let min = Math.round(this.term.replaceAll(" ", "").length * .5)
      console.log(min)
      for(let i = 0; i < this.titleSimilarity.length; i++) {
        let similar = this.titleSimilarity[i]
        if( similar.similarity > min ) similarJobs.push( similar.job )
      }
      return [...similarJobs, ...this.tagJobs]
    },
    user() {
      return this.$store.state.user
    },
  },
  methods: {
    compareToTerms(word) {
      let similarity = 0
      for(let i = 0; i < this.terms.length; i++) {
        let compared = wordSimilarity(word, this.terms[i])
        if( compared > similarity ) similarity = compared
      }
      return similarity
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.search {
  width: 100%;
  gap: 2rem;
  margin: auto;
  &_recent {
    grid-area: recent;
    width: 100%;
    --cols: 3;
  }

  &_companies {
    grid-area: companies;
  }

  &_categories {
    grid-area: categories;
  }

  &_sponsored {
    grid-area: sponsored;
    width: 100%;
  }

}

.modal {
  position: fixed;
  background-color: rgba(black, .5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>