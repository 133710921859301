<template>
<div class="search">
  <h2>Start your career</h2>
  <form action="#" method="post" @submit.prevent="search">
    <Icon icon="search" class="icon"></Icon>
    <input type="text" name="search" id="" v-model="term">
  </form>
</div>
</template>

<script>
import "@/firebaseApp"
import Icon from "@/components/Icon"

export default {
  components: {
    Icon,
  },
  data() {
    return {
      term: "",
      termTimeout: null,
    }
  },
  methods: {
    search() {
      clearTimeout(this.termTimeout)
      let route = {name: 'Search', params: {term: this.term}}
      if( !this.term ) {
        route = {name: 'Home'}
      }
      this.$router.push(route)
    }
  },
  watch: {
    term() {
      clearTimeout(this.termTimeout)
      this.termTimeout = setTimeout( () => {
        this.search()
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";
.search {
  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 1rem;
    color: var(--color1);
    line-height: 1;
    flex-shrink: 0;
  }
  form {
    position: relative;
    flex-grow: 1;
    height: 100%;
  }
  input {
    background: var(--color6);
    height: 100%;
    width: 100%;
    border: none;
    padding: 0 1em 0 2.5em;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    line-height: 1;
  }
  .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1rem;
    height: 1rem;
    width: 1rem;
    margin: auto;
    stroke: var(--color12);
    fill: transparent;
  }
}
</style>
