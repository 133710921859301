<template>
<div class="jobs">
  <a href="#" class="close">
    <Icon icon="cross" @click.prevent="goBack"/>
  </a>
  <JobOffersSection :jobs="jobs" class="list" :showTitle="false" :grid="false">
  </JobOffersSection>
</div>
</template>

<script>
// @ is an alias to /src
import JobOffersSection from "@/components/JobOffersSection"
import Icon from "@/components/Icon"

export default {
  name: 'StudentJob',
  props: {
    id: {required: true},
  },
  components: {
    JobOffersSection,
    Icon,
  },
  computed: {
    company() {
      if( this.job ) {
        return this.$store.getters['companies/id'](this.job.companyId)
      }
      return null
    },
    jobs() {
      return this.$store.state.jobs.all
      // return this.$store.getters['jobs/all']
    }
  },
  methods: {
    goBack() {
      if( this.$router.$previous.name != null ) {
        this.$router.push(this.$router.$previous)
      } else {
        this.$router.push({name: 'Home'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.jobs {
  --gap: 1rem;
  background-color: var(--color4);
  border-radius: var(--radius);
  padding: var(--gap);
  display: grid;
  gap: var(--gap);
  // grid-template-columns: min-content 1fr;
  // grid-template-rows: repeat(4, min-content) 1fr min-content;
  // grid-template-areas:
  //   "title title"
  //   "logo info"
  //   "tags tags"
  //   "description description"
  //   "buttons buttons"
  // ;
  max-width: 700px;
  margin: auto;

  .close {
    display: none;
  }

  .title {
    grid-area: title;
    color: var(--color1);
    margin: 0;
  }
  .logo {
    grid-area: logo;
    height: 75px;
    width: 75px;
    border: 1px solid var(--color1);
    border-radius: var(--radius);
  }
  .companyInfo {
    grid-area: info;
    height: 100%;
  }
  .company {
    margin: auto 0;
  }
  .location {
    margin: auto 0;
    font-weight: normal;
    color: var(--color1);
  }
  .tags {
    grid-area: tags;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 1em;
    font-size: 0.7rem;
    margin: auto 0 0 0;
    li {
      padding: .2em .4em .1em;
      line-height: 1;
      border-radius: 3px;
      border: 1px solid var(--color1);
      color: var(--color1);
    }
  }
  .description {
    grid-area: description;
    h3 {
      margin-top: 0;
    }
  }
  .buttons {
    grid-area: buttons;
    display: flex;
    gap: var(--gap);
    justify-content: center;
  }
}
</style>