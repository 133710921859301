<template>
  <form class="company" @submit.prevent="submit">
    <label for="logo">{{ $t('general.logo') }}</label>
    <FormInput type="file" id="logo" name="logo" v-model="logo" ref="logo"/>
    <label for="name">{{ $t('general.name') }}</label>
    <FormInput type="text" id="name" name="name" v-model="name"/>
    <label for="location">{{ $t('general.location') }}</label>
    <FormInput type="text" id="location" name="location" v-model="location"/>
    <label for="description">{{ $t('general.description') }}</label>
    <FormText type="text" id="description" name="description" v-model="description"/>
    <div class="actions">
      <FormButton v-text="company == null ? $t('general.add') : $t('general.update')"/>
      <FormButton class="cancel" @click.prevent="goBack" v-if="company == null">{{ $t('general.cancel') }}</FormButton>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src
import { getDatabase, ref, set, update, push, child, serverTimestamp } from "firebase/database";
import FormInput from "@/components/Input"
import FormText from "@/components/TextArea"
import FormButton from "@/components/Button"

import { getStorage, ref as sRef, uploadBytes } from "firebase/storage"
const storage = getStorage();  
const storageRef = sRef(storage, 'companies/logos/');  

export default {
  name: 'CompanyForm',
  components: {
    FormButton,
    FormInput,
    FormText,
  },
  props: {
    company: {default: null},
  },
  data() {
    return {
      emails: [],
      location: '',
      name: '',
      nrEmails: 1,
      logo: '',
      description: '',
    }
  },
  methods: {
    reset() {
      this.logo = '';
      this.name = '';
      this.emails = [];
      this.nrEmails = 1;
      if( this.company != null ) {
        this.name = this.company.name
        this.emails = [...this.company.emails]
        this.nrEmails = this.company.emails.length
      }
    },
    goBack() {
      if( this.$router.$previous.name != null ) {
        this.$router.push(this.$router.$previous)
      } else {
        this.$router.push({name: 'Companies'})
      }
    },
    async submit() {
      try {
        const db = getDatabase();
        let companyId = null
        let data = {name: this.name, location: this.location, description: encodeURIComponent(this.description)}
        if( this.company == null ) {
          companyId = push(child(ref(db), 'companies')).key;
          data.createdAt = serverTimestamp()
        } else {
          companyId = this.company.id
        }
        this.company == null ?
          await set(ref(db, 'companies/' + companyId), data) :
          await update(ref(db, 'companies/' + companyId), data)

        let file = this.$refs.logo.$el.files[0]
        let fileRef = sRef(storageRef, `${companyId}/${file.name}`)
        await uploadBytes(fileRef, file)
        await set(ref(db, `companies/${companyId}/logo`), file.name)
      } catch(err) {
        console.log(err)
      }
      if( this.company == null ) {
        this.goBack()
        this.$emit('sub')
      }
    },
    removeEmail(index) {
      if( this.nrEmails > 1 ) {
        this.emails = this.emails.filter( (email, i) => i != index )
        this.nrEmails -= 1
      }
    },
  },
  computed: {
    companies() {
      return this.$store.companies.all;
    },
  },
  watch: {
    company: {
      immediate: true,
      handler() {
        if( this.company != null ) {
          if(this.company.name != null) this.name = this.company.name;
          if(this.company.location != null) this.location = this.company.location;
          if(this.company.description != null) this.description = decodeURIComponent(this.company.description);
        }
      }
    }
  },
  mounted() {
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
.company {
  max-width: 600px;
  margin: auto;

  input, button, label, textarea {
    width: 100%;
  }
  textarea {
    height: 200px;
    resize: none;
  }
  input {
    margin-bottom: var(--gap);
  }
  .actions {
    display: flex;
    width: 100%;
    gap: var(--gap);
    .cancel {
      background-color: var(--color2);
    }
  }
}
</style>
