<template>
  <form class="company" @submit.prevent="addEmail">
    <ul v-for="user in users" :key="user.uid">
      <li class="email">
        <span v-text="user.email"></span>
        <ClickButton @click.prevent="resend(user)" :class="{disable: resending == user.email}">reenviar</ClickButton>
        <ClickButton @click.prevent="removeUser(user)">x</ClickButton>
      </li>
    </ul>
    <div class="email">
      <FormInput type="email" id="emails" name="email" v-model="email" placeholder="email"/>
      <FormButton>Adicionar</FormButton>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src
// import { getDatabase, ref, set, push, child, serverTimestamp } from "firebase/database";
import { getDatabase, ref, set } from "firebase/database";
import { getFunctions, httpsCallable } from 'firebase/functions'
import FormInput from "@/components/Input"
import FormButton from "@/components/Button"
import ClickButton from "@/components/ClickButton"
// import axios from '@/loadAxios'

const functions = getFunctions()
const addUser = httpsCallable(functions, 'recruiter-add')
const sendInvite = httpsCallable(functions, 'recruiter-sendInvite')
const remUser = httpsCallable(functions, 'remUser')

export default {
  name: 'CompanyUsersForm',
  components: {
    ClickButton,
    FormButton,
    FormInput,
  },
  props: {
    company: {default: null},
  },
  data() {
    return {
      location: '',
      name: '',
      nrEmails: 1,
      email: '',
      emails: [],
      resending: null,
    }
  },
  methods: {
    reset() {
      this.email = ''
    },
    goBack() {
      if( this.$router.$previous.name != null ) {
        this.$router.push(this.$router.$previous)
      } else {
        this.$router.push({name: 'Companies'})
      }
    },
    async resend(user) {
      this.resending = user.email
      await sendInvite({email: user.email})
      this.resending = null
    },
    submit() {
      const db = getDatabase();
      set(ref(db, `companies/${this.company.id}/emails`), this.emails )
      .then( () => {
        this.$emit('sub')
      });
    },
    async addEmail() {
      try {
        await addUser({email: this.email.trim(), companyId: this.company.id})
        this.reset()
        this.$store.dispatch('users/refresh')
      } catch( err ) {
        console.log(err)
      }
    },
    async removeUser(user) {
      try {
        await remUser({uid: user.uid})
      } catch(err) {
        console.log(err)
      }
    },
  },
  computed: {
    users() {
      return this.$store.getters['companies/users'](this.company.id)
    }
  },
  watch: {
    company: {
      immediate: true,
      handler() {
        if( this.company != null ) {
          this.name = this.company.name
          this.location = this.company.location
        }
      }
    }
  },
  mounted() {
    this.reset()
    this.$store.dispatch('companies/fetchUsers', this.company.id)
    this.$store.dispatch('users/fetch')
    // window.onbeforeunload = function() {
    //   return "If you leave this page you will lose your unsaved changes.";
    // }
  }
}
</script>

<style lang="scss" scoped>
.company {
  max-width: 600px;
  margin: auto;
  input, button, label {
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .email {
    display: flex;
    gap: var(--gap);
    margin-bottom: var(--gap);
    align-items: center;
    input, span {
        flex-grow: 1;
      }
      button {
        width: fit-content;
      }
  }

  .submit {
    margin-top: var(--gap);
  }
}
</style>
