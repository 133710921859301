<template>
  <div class="company" v-if="company">
    <div class="banner"></div>
    <div class="general">
      <img src="../../assets/logo.png" alt="" srcset="" class="logo">
      <h1 v-text="company.name"></h1>
    </div>
    <div class="info" v-html="company.description">
    </div>
    <div class="data">employees</div>
    <div class="social">
      <span>f</span>
      <span>i</span>
      <span>in</span>
    </div>
    <div class="buttons">
      <ClickButton>
        Follow
      </ClickButton>
      <ClickButton>
        Website
      </ClickButton>
    </div>
    <JobOffersSection :jobs="jobs" class="offers">
      Job Offers
    </JobOffersSection>
  </div>
</template>

<script>
// @ is an alias to /src
import JobOffersSection from "@/components/JobOffersSection"
import ClickButton from "@/components/ClickButton"

export default {
  name: 'StudentCompany',
  props: {
    companyId: {required: true},
  },
  components: {
    JobOffersSection,
    ClickButton,
  },
  computed: {
    company() {
      return this.$store.getters['companies/id'](this.companyId)
    },
    jobs() {
      return this.$store.getters['jobs/company'](this.companyId)
    }
  },
}
</script>

<style lang="scss" scoped>
.company {
  display: grid;
  grid-template-areas:
    "banner banner"
    "general general"
    "info info"
    "data social"
    "buttons buttons"
    "offers offers"
  ;
  .banner {
    background-color: var(--color1);
    height: 150px;
    width: 100%;
    border-radius: var(--radius);
    grid-area: banner;
  }
  .general {
    grid-area: general;
    .logo {
      height: 75px;
      width: 75px;
      border: 1px solid var(--color1);
      border-radius: var(--radius);
    }
  }
  .info {
    grid-area: info;
  }
  .data {
    grid-area: data;
  }
  .social {
    grid-area: social;
  }
  .buttons {
    grid-area: buttons;
    display: flex;
    gap: var(--gap);
  }
  .offers {
    grid-area: offers;
  }
}
</style>