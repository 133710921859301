<template>
  <div class="home">
    <JobOfferSlider :jobs="jobs" class="home_sponsored"/>
    <JobOffersSection :jobs="jobs.slice(0, 4)" class="home_recent" v-if="false">
      Recent Job Offers
    </JobOffersSection>
    <Companies class="home_companies"/>
    <JobOfferCategories/>
    <transition name="fade">
      <router-view name="modal" class="modal home_modal"></router-view>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import Companies from "@/components/Companies"
import JobOfferCategories from "@/components/JobOfferCategories"
import JobOfferSlider from "@/components/JobOfferSlider"
import JobOffersSection from "@/components/JobOffersSection"

export default {
  name: 'StudentHome',
  components: {
    Companies,
    JobOfferCategories,
    JobOffersSection,
    JobOfferSlider,
  },
  computed: {
    jobs() {
      return this.$store.state.jobs.all
    },
    user() {
      return this.$store.state.user
    },
    show() {
      return this.user != null // TODO: user is student
    }
  },
  methods: {
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.home {
  width: 100%;
  display: grid;
  gap: 2rem;
  margin: auto;
  grid-template-columns: 6fr 2fr 1fr;
  grid-template-areas:
    "sponsored sponsored sponsored"
    "recent companies categories";

  &_recent {
    grid-area: recent;
    width: 100%;
    --cols: 2;
  }

  &_companies {
    grid-area: companies;
  }

  &_categories {
    grid-area: categories;
  }

  &_sponsored {
    grid-area: sponsored;
    width: 100%;
  }

}

.modal {
  position: fixed;
  background-color: rgba(black, .5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>