<template>
<div class="offers" @mouseenter="pause" @mouseleave="unpause">
  <h2 class="offers_title">Sponsored Job Offers</h2>
  <a href="#" class="next" @click.prevent="goNext">
    <Icon icon="arrow"/>
  </a>
  <a href="#" class="prev" @click.prevent="goPrev">
    <Icon icon="arrow"/>
  </a>
  <ul class="offers_list"
    :class="{'offers_list-next': goingNext, 'offers_list-prev': goingPrev}"
    ref="list"
    @scroll.passive="adjustItems">
    <li v-for="job in jobsList" :key="job.id" class="job">
      <JobOfferButton :job="job"/>
    </li>
  </ul>
</div>
</template>

<script>
// @ is an alias to /src
import Icon from "@/components/Icon"
import JobOfferButton from "@/components/JobOfferButton"

export default {
  name: 'JobOfferSlider',
  components: {
    Icon,
    JobOfferButton,
  },
  props: {
    jobs: { required: true },
  },
  data() {
    return {
      selected: 0,
      animationInterval: null,
      adjustTimeout: null,
      goingNext: false,
      goingPrev: false,
    }
  },
  computed: {
    jobsList() {
      // console.log(this.selected)
      let firsts = this.jobs.slice( -1 - this.selected )
      let lasts = this.jobs.slice( 0, this.jobs.length - 1 - this.selected )
      let jobs = [...firsts, ...lasts]
      return jobs
    },
  },
  methods: {
    adjustItems() {
      clearTimeout(this.adjustTimeout)
      this.adjustTimeout = setTimeout( () => {
        console.log('scrolled')
      }, 50)
    },
    goNext() {
      this.goingNext = true
      setTimeout(this.scrollToSelected, 500)
      // this.scrollToSelected()
    },
    goPrev() {
      this.goingPrev = true
      setTimeout(this.scrollToSelected, 500)
      // this.selected--
      // this.scrollToSelected()
    },
    finishNext() {
      this.selected++
      this.goingNext = false
    },
    pause() {
      clearInterval(this.animationInterval)
    },
    scrollToSelected() {
      let jobs = this.$refs.list.querySelectorAll('.job')
      if( this.goingNext ) {
        this.selected--
        this.goingNext = false
      } else if( this.goingPrev ) {
        this.selected++
        this.goingPrev = false
      }
      // if( this.selected < 0) this.selected = (jobs.length - 2 + this.selected)
      if( this.selected < 0) this.selected += jobs.length
      // this.selected = this.selected % (jobs.length - 2)
      this.selected = this.selected % jobs.length
      // let next = jobs[this.selected]
      // this.$refs.list.scroll({left: next.offsetLeft, behavior: 'smooth'})
    },
    unpause() {
      this.pause()
      this.animationInterval = setInterval( () => {
        this.goNext()
      }, 2000)
    },
  },
  watch: {
    selected() {
    },
    jobsList() {
    }
  },
  mounted() {
    this.unpause()
  },
  destroyed() {
    this.pause()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_queries.scss";

.offers {
  --gap: 2rem;
  border-radius: 1.25em;
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  @include media(desktop) {
    padding: 1em;
  }
  .next, .prev {
    position: absolute;
    top: 3rem;
    bottom: 1rem;
    width: 50px;
    z-index: 20;
    transition: .2s ease-out opacity;
    display: flex;
    svg {
      width: 50%;
      height: 100%;
      margin: auto;
      fill: transparent;
      stroke: var(--color1);
    }
    &:not(:hover) {
      opacity: 0;
    }
  }
  .next {
    right: 0;
    background-image: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,1) 50%);
    svg {
      transform: rotate(180deg);
    }
  }
  .prev {
    left: 0;
    background-image: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0));
  }
  &_list {
    position: relative;
    padding: 1em;
    list-style: none;
    gap: var(--gap);
    display: flex;
    overflow: auto;
    @include media(desktop) {
      padding: 0;
      overflow: hidden;
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
    }
    .job {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 1;
      flex-wrap: wrap;
      overflow: hidden;
      transform: translateX(calc(-100% - var(--gap)));
      @include media(desktop) {
        // flex-basis: 0;
        width: calc((100% / 3) - (4rem / 3));
      }
      // &:nth-child(n+6)  {
      //   display: none;
      // }
    }
    &-next .job {
      transform: translateX(calc(-200% - var(--gap) * 2));
      transition: transform .2s ease-out;
    }
    &-prev .job {
      transform: translateX(0);
      transition: transform .2s ease-out;
    }
  }
  &_title {
    color: var(--color1);
    text-align: left;
    margin: 0 1em;
    @include media(desktop) {
      margin: 0;
    }
  }
}
</style>
