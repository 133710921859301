import Vue from 'vue'
import VueRouter from 'vue-router'

import Code from '../views/Code.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
// import Profile from '../views/Profile.vue'

import AdminHome from '../views/admin/Home.vue'
import AdminCompanies from '../views/admin/Companies.vue'
import AdminNewCompany from '../views/admin/NewCompany.vue'
import AdminEditCompany from '../views/admin/EditCompany.vue'
import AdminProfile from '../views/admin/Profile.vue'
import AdminCompaniesQR from '../views/admin/CompaniesQR.vue'

import CompanyHome from '../views/company/Home.vue'
import CompanyProfile from '../views/company/Profile.vue'
import CompanyJobsNew from '../views/company/JobsNew.vue'
import CompanyJob from '../views/company/Job.vue'

import StudentHome from '../views/student/Home.vue'
import StudentCompany from '../views/student/Company.vue'
import StudentJobs from '../views/student/Jobs.vue'
import StudentJob from '../views/student/Job.vue'
import StudentProfile from '../views/student/Profile.vue'
import StudentSearch from '../views/student/Search.vue'

// import JobOfferModal from '../views/JobOfferModal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/code',
    component: Code,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    name: 'JobsNew',
    path: '/jobs/new',
    components: {
      // student: StudentJobs,
      default: CompanyJobsNew,
    },
    props: true,
  },
  {
    path: '/',
    component: Home,
    children: [
      {
        name: 'Home',
        path: '',
        components: {
          admin: AdminHome,
          company: CompanyHome,
          student: StudentHome,
        },
      },
      {
        name: 'Jobs',
        path: 'jobs',
        components: {
          student: StudentJobs,
        },
        props: {student: true},
      },
      {
        name: 'JobOffer',
        path: 'jobs/:id',
        components: {
          student: StudentJob,
          company: CompanyJob,
        },
        props: {student: true, company: true},
      },
      {
        name: 'Companies',
        path: 'companies',
        components: {
          admin: AdminCompanies,
        },
        children: [
          {
            name: 'NewCompany',
            path: 'new',
            component: AdminNewCompany,
          },
        ]
      },
      {
        name: 'CompaniesQR',
        path: 'companies/qr',
        components: {
          admin: AdminCompaniesQR,
        },
      },
      {
        name: 'EditCompany',
        path: 'companies/:companyId',
        props: {admin: true},
        components: {admin: AdminEditCompany},
      },
      {
        name: 'Profile',
        path: 'profile',
        components: {
          admin: AdminProfile,
          company: CompanyProfile,
          student: StudentProfile,
        },
      },
      {
        name: 'Search',
        path: 's/:term',
        components: {
          // admin: Profile,
          // company: CompanyProfile,
          student: StudentSearch,
        },
        props: {student: true},
      },
      {
        name: 'Company',
        path: 'c/:companyId',
        components: {
          student: StudentCompany,
        },
        props: {student: true, admin: true},
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

VueRouter.prototype.$previous = null

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.$previous = null

router.beforeEach((to, from, next) => {
  router.$previous = from
  next()
  // if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  // else next()
})

export default router
